import Base from "./base.js"

class Datepicker extends Base {
  constructor(element) {
    super()

    this.TRANSITION_DURATION = 500

    // NOTE: DOMを吐き出す場所指定element
    this.datepickerContainerEl = element
    this.id = element.dataset.datepickerContainer
    this.datepickerEl = this.datepickerContainerEl.querySelector('[data-datepicker]')
    this.datepickerScrimEl = this.datepickerContainerEl.querySelector('[data-datepicker-scrim]')

    // NOTE: datepicker functions
    this.datepickerTriggerEl = document.querySelector(`[data-datepicker-trigger="${this.id}"]`)
    this.datepickerClearEl = document.querySelector(`[data-datepicker-clear="${this.id}"]`)

    // NOTE: trigger 内の value element
    this.valueEl = this.datepickerTriggerEl.querySelector(`[data-datepicker-value]`)

    // NOTE: init current date
    this.currentDate = new Date()

    // NOTE: init selected date
    this.selectedDateEl = document.querySelector(`[data-datepicker-selected="${this.id}"]`)
    this.initSelectedDate()

    // NOTE: init display date object
    this.initDisplayDate()

    // NOTE: 初回 update calendar
    this.updateCalendar()

    this.initialize()
  }

  initialize() {
    this.datepickerTriggerEl.addEventListener('click', () => {
      this.toggleCalendar()
    })
    this.datepickerScrimEl.addEventListener('click', () => {
      this.toggleCalendar()

      setTimeout(() => { // NOTE: 時間差で初期化
        this.initDisplayDate()
        this.updateCalendar()
      }, this.TRANSITION_DURATION)
    })
    this.datepickerClearEl.addEventListener('click', (e) => {
      this.clearSelectedDate()
      this.updateDisplayDate(new Date()) // NOTE: こっちはカレンダーの表示月をもとに戻すために、引数に今日をいれる
      this.updateCalendar()
      e.stopPropagation() // NOTE: datepickerTriggerEl へのイベント伝搬停止
    })
  }

  initDisplayDate() {
    // NOTE: 初期値が存在する場合は display date にセット
    if (this.selectedDate.getFullYear) {
      this.updateDisplayDate(new Date(this.selectedDate.getTime()))

    } else {
      this.updateDisplayDate(new Date()) // NOTE: 存在しない場合は今日の日付
    }
  }

  initSelectedDate() {
    // NOTE: 初期値が存在する場合は selected date にセット
    if (this.valueEl.value) {
      this.updateSelectedDate(this.valueEl.value)

    } else {
      this.clearSelectedDate()
    }
  }

  updateDisplayDate(date) {
    if (date) this.displayDate = date
    this.displayYear = this.displayDate.getFullYear()
    this.displayMonth = this.displayDate.getMonth() + 1
    this.displayDay = this.displayDate.getDate()
    this.displayFirstDate = new Date(this.displayYear, this.displayDate.getMonth(), 1)
    this.displayFirstDayOfWeek = this.displayFirstDate.getDay()
    this.displayLastDate = new Date(this.displayYear, this.displayMonth, 0)
    this.displayLastDay = this.displayLastDate.getDate()
  }

  updateSelectedDate(dateStr) {
    if (dateStr) this.selectedDateEl.innerHTML = dateStr
    this.selectedDateArray = this.selectedDateEl.innerHTML.split('/')
    this.selectedDateYear = Number(this.selectedDateArray[0])
    this.selectedDateMonth = Number(this.selectedDateArray[1]) - 1
    this.selectedDateDay = Number(this.selectedDateArray[2])
    this.selectedDate = new Date(this.selectedDateYear, this.selectedDateMonth, this.selectedDateDay)

    // NOTE: 実際 submit　する値
    this.valueEl.dataset.datepickerValue = this.selectedDateEl.innerHTML
    this.valueEl.value = this.selectedDateEl.innerHTML

    // NOTE: selected date が存在する場合はクリアボタン表示
    this.datepickerClearEl.dataset.toggleFade = 'active'
  }

  clearSelectedDate() {
    this.selectedDateEl.innerHTML = ''
    this.selectedDateArray = []
    this.selectedDateYear = 0
    this.selectedDateMonth = 0
    this.selectedDateDay = 0
    this.selectedDate = {}

    // NOTE: 実際 submit　する値
    this.valueEl.dataset.datepickerValue = ''
    this.valueEl.value = ''

    // NOTE: selected date が存在しないのでクリアボタン非表示
    this.datepickerClearEl.dataset.toggleFade = ''
  }

  updatePrevDate() {
    this.displayDate.setMonth(this.displayDate.getMonth() - 1)
    this.updateDisplayDate()
  }

  updateNextDate() {
    this.displayDate.setMonth(this.displayDate.getMonth() + 1)
    this.updateDisplayDate()
  }

  updateCalendar() {
    // NOTE: create prev element
    this.prevDOM = document.createElement('button')
    this.prevDOM.className = 'datepicker__prev'
    this.prevDOM.dataset.hover = 'dark'
    this.prevDOM.type = 'button'
    this.prevIconDOM = document.createElement('i')
    this.prevIconDOM.className = 'fa-sharp fa-light fa-angle-left'
    this.prevDOM.appendChild(this.prevIconDOM)
    this.prevDOM.addEventListener('click', () => {
      this.updatePrevDate()
      this.updateCalendar()
    })

    // NOTE: create year and month element
    this.monthYearDOM = document.createElement('div')
    this.monthYearDOM.className = 'datepicker__yearAndMonth'
    this.monthYearSpanDOM = document.createElement('span')
    this.monthYearSpanDOM.innerHTML = `${this.displayYear}年` // TODO: 該当日付にする
    this.monthMonthSpanDOM = document.createElement('span')
    this.monthMonthSpanDOM.innerHTML = `${this.displayMonth}月` // TODO: 該当日付にする
    this.monthYearDOM.appendChild(this.monthYearSpanDOM)
    this.monthYearDOM.appendChild(this.monthMonthSpanDOM)

    // NOTE: create next element
    this.nextDOM = document.createElement('button')
    this.nextDOM.className = 'datepicker__next'
    this.nextDOM.dataset.hover = 'dark'
    this.nextDOM.type = 'button'
    this.nextIconDOM = document.createElement('i')
    this.nextIconDOM.className = 'fa-sharp fa-light fa-angle-right'
    this.nextDOM.appendChild(this.nextIconDOM)
    this.nextDOM.addEventListener('click', () => {
      this.updateNextDate()
      this.updateCalendar()
    })

    // NOTE: create header element
    this.headerEl = document.createElement('div')
    this.headerEl.className = 'datepicker__header'
    this.headerEl.appendChild(this.prevDOM)
    this.headerEl.appendChild(this.monthYearDOM)
    this.headerEl.appendChild(this.nextDOM)

    // NOTE: create container element
    this.containerDOM = document.createElement('div')
    this.containerDOM.className = 'datepicker__container'
    this.weekNameArray = ['日', '月', '火', '水', '木', '金', '土']
    this.emptyDayCount = 7 + this.displayFirstDayOfWeek // NOTE: 当月の始まりの空白の曜日
    this.displayDateCount = (this.displayLastDay + this.emptyDayCount) > 42 ? 49 : 42 // NOTE: カレンダーに表示するセル数、6行目に突入する場合は49、それ以外は42

    for (let i = 0; i < this.displayDateCount; i++) {
      const cellDOM = document.createElement('div')
      cellDOM.className = 'datepicker__cell'

      // NOTE: 最初の7セルは曜日の文字を入れる
      if (i < 7) {
        cellDOM.innerHTML = this.weekNameArray[i]

      // NOTE: emptyDayCount分はなにも入れない
      } else if (i < this.emptyDayCount) {

      // NOTE: emptyDayCount分が終わったら日付を入れる
      } else if (i < (this.displayLastDay + this.emptyDayCount)) {
        const dayNumber = i - this.emptyDayCount + 1 // NOTE: (i - this.emptyDayCount) が 0から始まる、それに +1
        const cellSpanDOM = document.createElement('span')
        cellSpanDOM.innerHTML = dayNumber
        cellDOM.appendChild(cellSpanDOM)
        cellDOM.dataset.hover = 'dark'

        // NOTE: 今日の判定
        if (
          this.currentDate.getFullYear() === this.displayYear &&
          (this.currentDate.getMonth() + 1) === this.displayMonth &&
          this.currentDate.getDate() === dayNumber
        ) {
          cellDOM.classList.add('current')
        }

        // NOTE: selected date の判定
        if (
          this.selectedDate.getFullYear &&
          this.selectedDate.getFullYear() === this.displayYear &&
          (this.selectedDate.getMonth() + 1) === this.displayMonth &&
          this.selectedDate.getDate() === dayNumber
        ) {
          cellDOM.classList.add('selected')
        }

        // NOTE: for cursor-pointer css
        cellDOM.dataset.datepickerDate = dayNumber

        // NOTE: cell click function
        cellDOM.addEventListener('click', () => {
          const dateStr = `${this.displayYear}/${this.displayMonth}/${cellSpanDOM.innerHTML}`
          this.updateSelectedDate(dateStr)
          this.updateDisplayDate(new Date(this.selectedDate.getTime()))
          this.updateCalendar()
          // this.toggleCalendar()
        })
      }

      this.containerDOM.appendChild(cellDOM)
    }

    // NOTE: create footer element
    this.footerDOM = document.createElement('div')
    this.footerDOM.className = 'datepicker__footer'
    this.footerButtonGroupDOM = document.createElement('div')
    this.footerButtonGroupDOM.className = 'button-group'

    // NOTE: create clear button
    this.clearButtonDOM = document.createElement('button')
    this.clearButtonDOM.className = 'button'
    this.clearButtonDOM.type = 'button'
    this.clearButtonSpanDOM = document.createElement('span')
    this.clearButtonSpanDOM.className = 'button__content button--default button--dense'
    this.clearButtonSpanDOM.dataset.hover = 'dark'
    this.clearButtonSpanTextDOM = document.createElement('span')
    this.clearButtonSpanTextDOM.innerHTML = 'Clear'
    this.clearButtonSpanIconDOM = document.createElement('i')
    this.clearButtonSpanIconDOM.className = 'fa-sharp fa-light fa-eraser mr--sm'
    this.clearButtonSpanDOM.appendChild(this.clearButtonSpanIconDOM)
    this.clearButtonSpanDOM.appendChild(this.clearButtonSpanTextDOM)
    this.clearButtonDOM.appendChild(this.clearButtonSpanDOM)
    this.clearButtonDOM.addEventListener('click', () => {
      this.clearSelectedDate()
      this.updateDisplayDate() // NOTE: こっちはカレンダーの表示月はそのままなので引数なし
      this.updateCalendar()
    })

    // NOTE: create ok button
    this.okButtonDOM = document.createElement('button')
    this.okButtonDOM.className = 'button'
    this.okButtonDOM.type = 'button'
    this.okButtonSpanDOM = document.createElement('span')
    this.okButtonSpanDOM.className = 'button__content button--primary button--dense'
    this.okButtonSpanDOM.dataset.hover = 'light'
    this.okButtonSpanTextDOM = document.createElement('span')
    this.okButtonSpanTextDOM.innerHTML = 'OK'
    this.okButtonSpanIconDOM = document.createElement('i')
    this.okButtonSpanIconDOM.className = 'fa-sharp fa-light fa-check mr--sm'
    this.okButtonSpanDOM.appendChild(this.okButtonSpanIconDOM)
    this.okButtonSpanDOM.appendChild(this.okButtonSpanTextDOM)
    this.okButtonDOM.appendChild(this.okButtonSpanDOM)
    this.okButtonDOM.addEventListener('click', () => {
      this.toggleCalendar()

      setTimeout(() => { // NOTE: 時間差で初期化
        this.initDisplayDate()
        this.updateCalendar()
      }, this.TRANSITION_DURATION)
    })

    // NOTE: add to button group
    this.footerButtonGroupDOM.appendChild(this.clearButtonDOM)
    this.footerButtonGroupDOM.appendChild(this.okButtonDOM)

    // NOTE: add to footer
    this.footerDOM.appendChild(this.footerButtonGroupDOM)

    // NOTE: init datepicker container DOM
    this.datepickerEl.innerHTML = ''

    // NOTE: create datepicker container DOM
    this.datepickerEl.appendChild(this.headerEl)
    this.datepickerEl.appendChild(this.containerDOM)
    this.datepickerEl.appendChild(this.footerDOM)
  }

  toggleCalendar() {
    this.datepickerTriggerEl.classList.toggle('open') // NOTE: datepicker value DOM
    this.datepickerContainerEl.classList.toggle('open')
    this.datepickerEl.classList.toggle('open')
    document.body.classList.toggle('frame')
    this.datepickerScrimEl.dataset.toggleFade = (this.datepickerScrimEl.dataset.toggleFade) ? '' : 'active'
  }
}

export default Datepicker
