import Base from "./base.js"

class Modal extends Base {
  constructor(element) {
    super()

    this.modalContainerEl = element
    this.id = element.dataset.modalContainer
    this.triggerEls = document.querySelectorAll(`[data-modal-trigger="${this.id}"]`)
    this.modalEl = this.modalContainerEl.querySelector('[data-modal]')
    this.modalScrimEl = this.modalContainerEl.querySelector('[data-modal-scrim]')

    this.initialize()
  }

  initialize() {
    for (let i = 0; i < this.triggerEls.length; i++) {
      this.triggerEls[i].addEventListener('click', () => {
        this.modalContainerEl.classList.toggle('open')
        this.modalEl.classList.toggle('open')
        document.body.classList.toggle('frame')
        if (this.modalScrimEl) {
          this.modalScrimEl.dataset.toggleFade = (this.modalScrimEl.dataset.toggleFade) ? '' : 'active'
        }
      })
    }
  }
}

export default Modal
