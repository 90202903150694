import Base from "./base.js"

class Ripple extends Base {
  constructor(element) {
    super()

    this.DRAWER_ID = 'drawer'
    this.RIPPLE_CLASS_NAME = 'ripple'
    this.buttonEl = element
    this.rippleDOM = document.createElement('span')
    this.rippleEls = []
    this.diameter = 0
    this.radius = 0
    this.clickPosition = {}
    this.initialize()
  }

  initialize() {
    this.update()
    this.buttonEl.addEventListener('click', (e) => {
      this.create(e)
    })
  }

  update() {
    this.diameter = Math.max(this.buttonEl.clientWidth, this.buttonEl.clientHeight)
    this.radius = this.diameter / 2
  }

  create(event) {
    // for position fixed drawer
    if (this.buttonEl.offsetParent && this.buttonEl.offsetParent.id == this.DRAWER_ID) {
      this.clickPosition = this.getOffset(event)

    } else {
      this.clickPosition = { x: event.layerX, y: event.layerY }
    }
    // console.dir(this.buttonEl.offsetParent.id)
    // console.dir(`this.clickPosition.x: ${this.clickPosition.x} / this.clickPosition.y: ${this.clickPosition.y}`)
    // console.dir(`event.layerX: ${event.layerX} / event.layerY: ${event.layerY}`)

    this.rippleDOM.style.width = this.rippleDOM.style.height = `${this.diameter}px`
    this.rippleDOM.style.left = `${this.clickPosition.x - this.radius}px`
    this.rippleDOM.style.top = `${this.clickPosition.y- this.radius}px`
    this.rippleDOM.classList.add(this.RIPPLE_CLASS_NAME)

    this.rippleEls = this.buttonEl.querySelectorAll(`.${this.RIPPLE_CLASS_NAME}`)
    for (let i = 0; i < this.rippleEls.length; i++) {
      if (this.rippleEls[i]) {
        this.rippleEls[i].remove()
      }
    }

    this.buttonEl.appendChild(this.rippleDOM)
  }

  getOffset(event) {
    let target = event.target
    let x = 0
    let y = 0

    while (target && !isNaN(target.offsetLeft) && !isNaN(target.offsetTop)) {
      x += target.offsetLeft - target.scrollLeft
      y += target.offsetTop - target.scrollTop
      target = target.offsetParent
    }

    x = event.clientX - x
    y = event.clientY - y

    return { x: x, y: y }
  }
}

export default Ripple
