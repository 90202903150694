import Base from "./base.js"

class LoadingOverlay extends Base {
  constructor(element) {
    super()

    this.triggerEl = element
    this.id = this.triggerEl.dataset.loadingOverlay
    this.loadingEl = document.getElementById(this.id)
    this.isShown = false; // NOTE: showが実行されたかどうかのフラグ

    this.initialize()
  }
  initialize() {
    // NOTE: for turbolinks browser back
    this.loadingEl.dataset.toggleFade = ''
    document.body.classList.remove('frame')
    this.triggerEl.classList.remove('disable-button')

    // NOTE: ボタンが含まれているフォームを取得
    const form = this.triggerEl.closest('form')
    if (form) {
      // NOTE: フォームのsubmitイベントにリスナーを追加
      form.addEventListener('submit', () => {
        this.show()
      })
    }
  }
  show() {
    if (this.isShown) return; // NOTE: すでにshowが実行されていれば、何もしない
    this.isShown = true; // NOTE: showが実行されたことを記録

    this.loadingEl.dataset.toggleFade = 'active'
    document.body.classList.add('frame')
    this.triggerEl.classList.add('disable-button')
  }
}

class LoadingButton extends Base {
  constructor(element) {
    super()

    this.triggerEl = element
    this.toggleFadeActiveEls = {}
    this.toggleFadeInActiveEls = {}
    this.isShown = false; // NOTE: showが実行されたかどうかのフラグ

    this.initialize()
  }
  initialize() {
    // NOTE: for turbolinks browser back
    this.update()

    for (let j = 0; j < this.toggleFadeActiveEls.length; j++) {
      this.toggleFadeActiveEls[j].dataset.toggleFade = 'active'
    }
    for (let j = 0; j < this.toggleFadeInActiveEls.length; j++) {
      this.toggleFadeInActiveEls[j].dataset.toggleFade = 'active'
    }
    for (let j = 0; j < this.toggleLoadingButtonEls.length; j++) {
      this.toggleLoadingButtonEls[j].dataset.toggleFade = ''
    }
    this.triggerEl.classList.remove('disable-button')

    // NOTE: ボタンが含まれているフォームを取得
    const form = this.triggerEl.closest('form')
    if (form) {
      // NOTE: フォームのsubmitイベントにリスナーを追加
      form.addEventListener('submit', () => {
        this.show()
      })
    }
  }
  show() {
    if (this.isShown) return; // NOTE: すでにshowが実行されていれば、何もしない
    this.isShown = true; // NOTE: showが実行されたことを記録

    this.update()

    for (let j = 0; j < this.toggleFadeActiveEls.length; j++) {
      this.toggleFadeActiveEls[j].dataset.toggleFade = ''
    }
    for (let j = 0; j < this.toggleFadeInActiveEls.length; j++) {
      this.toggleFadeInActiveEls[j].dataset.toggleFade = 'active'
    }
    this.triggerEl.classList.add('disable-button')
  }
  update() {
    this.toggleFadeActiveEls = this.triggerEl.querySelectorAll('[data-toggle-fade="active"]')
    this.toggleFadeInActiveEls = this.triggerEl.querySelectorAll('[data-toggle-fade]:not([data-toggle-fade="active"])')
    this.toggleLoadingButtonEls = this.triggerEl.querySelectorAll('[data-toggle-loading-button]')
  }
}

export const Loading = {
  LoadingOverlay,
  LoadingButton,
}
