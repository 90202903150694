import Base from "./base.js"

class Sidebar extends Base {
  constructor() {
    super()

    this.SIDEBAR_ID = 'sidebar'
    this.CONTENT_ID = 'content'
    this.HAMBURGER_ID = 'hamburger'
    this.SCRIM_ID = 'scrim'
    this.MAIN_APP_TOGGLE_SIDEBAR_ID = 'main-app-toggle-sidebar'
    this.MAIN_APP_TOGGLE_SIDEBAR_ATTR = `[data-toggle-switch-content="${this.MAIN_APP_TOGGLE_SIDEBAR_ID}"]`
    this.DATA_OPEN_SIDEBAR_ATTR = '[data-open-sidebar]'
    this.DATA_CLOSE_SIDEBAR_ATTR = '[data-close-sidebar]'

    this.sidebarEl = document.getElementById(this.SIDEBAR_ID)
    this.contentEl = document.getElementById(this.CONTENT_ID)
    this.hamburgerEl = document.getElementById(this.HAMBURGER_ID)
    this.scrimEl = document.getElementById(this.SCRIM_ID)
    this.mainAppToggleSidebarEl = document.getElementById(this.MAIN_APP_TOGGLE_SIDEBAR_ID)
    this.toggleSwitchContentEls = {}
    this.openSidebarEls = document.querySelectorAll(this.DATA_OPEN_SIDEBAR_ATTR)
    this.closeSidebarEls = document.querySelectorAll(this.DATA_CLOSE_SIDEBAR_ATTR)

    this.scrimEl.dataset.closeSidebar = ''
    this.initialize()
  }

  initialize() {
    for (let i = 0; i < this.openSidebarEls.length; i++) {
      this.openSidebarEls[i].addEventListener('click', () => {
        this.open()
      })
    }
    for (let i = 0; i < this.closeSidebarEls.length; i++) {
      this.closeSidebarEls[i].addEventListener('click', () => {
        this.close()
      })
    }
    this.mainAppToggleSidebarEl.addEventListener('click', () => {
      this.toggle()
    })
  }

  open() {
    this.sidebarEl.classList.add('show')
    document.body.classList.add('frame--sidebar')
    this.scrimEl.dataset.closeSidebar = this.SIDEBAR_ID
    this.scrimEl.dataset.toggleFade = 'active'
  }

  close() {
    this.sidebarEl.classList.remove('show')
    document.body.classList.remove('frame--sidebar')
    this.scrimEl.dataset.closeSidebar = ''
    this.scrimEl.dataset.toggleFade = ''
  }

  toggle() {
    this.update()
    this.sidebarEl.classList.toggle('disable-sidebar')
    this.contentEl.classList.toggle('disable-sidebar')

    for (let i = 0; i < this.toggleSwitchContentEls.length; i++) {
      this.toggleSwitchContentEls[i].dataset.toggleFade = (this.toggleSwitchContentEls[i].dataset.toggleFade) ? '' : 'active'
    }
  }

  update() {
    this.toggleSwitchContentEls = document.querySelectorAll(this.MAIN_APP_TOGGLE_SIDEBAR_ATTR)
  }
}

export default Sidebar
