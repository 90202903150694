// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import 'materialize-css/dist/js/materialize'
import { Chart } from 'chart.js/dist/Chart'
import ChartDataLabels from 'chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels' // NOTE: https://github.com/chartjs/chartjs-plugin-datalabels/issues/150
import _ from 'lodash'

// NOTE: modules import
import TargetData from 'modules/target_data'
import Datepicker from "modules/datepicker"
import HashLinkScroll from "modules/hash-link-scroll"
import { Loading } from "modules/loading"
import Modal from "modules/modal"
import Ripple from "modules/ripple"
import ScrollBack from "modules/scroll-back"
import { SelectBox } from "modules/selectbox.js"
import Sidebar from "modules/sidebar"
import SlideToggle from "modules/slide-toggle"
import Snackbar from "modules/snackbar"
import Tab from "modules/tab"

function init_module() {
  // NOTE: ページに 'data-initialized' 属性がすでに設定されている場合、この関数は何もせずに終了します。
  if (document.body.hasAttribute('data-initialized')) return

  // NOTE: ex) Datepicker module
  const datepickerContainerEls = document.querySelectorAll('[data-datepicker-container]')
  for (let i = 0; i < datepickerContainerEls.length; i++) {
    new Datepicker(datepickerContainerEls[i])
  }

  // NOTE: ex) Hash link scroll module
  const hashLinkTriggerEls = document.querySelectorAll('[data-href^="#"]')
  for (let i = 0; i < hashLinkTriggerEls.length; i++) {
    const element = hashLinkTriggerEls[i]
    const hashLinkScroll = new HashLinkScroll(element.dataset.href.substring(1))

    element.addEventListener('click', () => {
      hashLinkScroll.scrollTarget()
    })
  }

  // NOTE: ex) Loading module -> overlay
  const loadingOverlayEls = document.querySelectorAll('[data-loading-overlay]')
  for (let i = 0; i < loadingOverlayEls.length; i++) {
    const element = loadingOverlayEls[i]
    const loadingOverlay = new Loading.LoadingOverlay(element)

    element.addEventListener('click', () => {
      loadingOverlay.show()
    })
  }

  // NOTE: ex) Loading module -> button
  const loadingButtonEls = document.querySelectorAll('[data-loading-button]')
  for (let i = 0; i < loadingButtonEls.length; i++) {
    const element = loadingButtonEls[i]
    const loadingButton = new Loading.LoadingButton(element)

    element.addEventListener('click', () => {
      loadingButton.show()
    })
  }

  // NOTE: ex) Modal module
  const modalContainerEls = document.querySelectorAll('[data-modal-container]')
  for (let i = 0; i < modalContainerEls.length; i++) {
    new Modal(modalContainerEls[i])
  }

  // NOTE: ex) Scroll back module
  const scrollBackEls = document.querySelectorAll('[data-scroll-back]')
  for (let i = 0; i < scrollBackEls.length; i++) {
    new ScrollBack(scrollBackEls[i])
  }

  // NOTE: ex) Selectbox module -> single
  const selectboxContainerEls = document.querySelectorAll('[data-selectbox-container]')
  for (let i = 0; i < selectboxContainerEls.length; i++) {
    new SelectBox.SelectBoxSingle(selectboxContainerEls[i])
  }

  // NOTE: ex) Selectbox module -> multiple
  const selectboxMultipleContainerEls = document.querySelectorAll('[data-selectbox-multiple-container]')
  for (let i = 0; i < selectboxMultipleContainerEls.length; i++) {
    new SelectBox.SelectBoxMultiple(selectboxMultipleContainerEls[i])
  }

  // NOTE: ex) Sidebar module
  new Sidebar()

  // NOTE: ex) Slide toggle module
  const slideToggleTriggerEls = document.querySelectorAll('[data-slide-toggle]')
  for (let i = 0; i < slideToggleTriggerEls.length; i++) {
    new SlideToggle(slideToggleTriggerEls[i])
  }

  // NOTE: ex) Snackbar module
  const snackbarEls = document.querySelectorAll('[data-snackbar]')
  for (let i = 0; i < snackbarEls.length; i++) {
    const snackbar = new Snackbar(snackbarEls[i])
    snackbar.hide() // NOTE: DOMContentLoaded時にsnackbarを非表示にする処理
  }

  // NOTE: ex) Tab module
  const tabContainerEls = document.querySelectorAll('[data-tab-container]')
  for (let i = 0; i < tabContainerEls.length; i++) {
    new Tab(tabContainerEls[i])
  }

  document.body.classList.remove('preload') // NOTE: 画面の初期描写時にtransitionをさせないようにする

  // NOTE: ex) Hash link scroll module -> ページロード時の自動アンカーリンク
  const hashLinkScroll = new HashLinkScroll(location.hash.substring(1))
  hashLinkScroll.scrollTarget()

  // NOTE: ex) Ripple module
  const buttonEls = document.querySelectorAll('[data-ripple]')
  for (let i = 0; i < buttonEls.length; i++) {
    new Ripple(buttonEls[i])
  }

  // NOTE: ページの初期化が完了したら、ページに 'data-initialized' 属性を設定します。
  document.body.setAttribute('data-initialized', 'true')
  console.log('module inited.')
}

function clean_module() {
  // NOTE: ページ表示毎に 'data-initialized' 属性を削除します。
  // NOTE: これにより、ページ表示毎に init 関数が発火するようになります。
  if (document.body.hasAttribute('data-initialized')) {
    document.body.removeAttribute('data-initialized')

    console.log('module cleaned.')
  }
}

// NOTE: ページがレンダリングされるたびに clean 関数を発火させます
document.addEventListener('turbolinks:before-render', clean_module)

// NOTE: load, render
document.addEventListener('turbolinks:load', init_module)

Chart.plugins.register(ChartDataLabels)
Chart.defaults.global.defaultFontFamily = 'din-2014, "M PLUS 1p", sans-serif'
Chart.defaults.global.defaultFontColor = '#434646'
Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
  formatter: (value, ctx) => {
    let sum = 0

    const dataArr = ctx.chart.data.datasets[0].data
    dataArr.map(data => {
      sum += data
    })
    const percentage = `${(value * 100 / sum).toFixed(2)} %`
    if ((value * 100 / sum).toFixed(2) < 10) {
      return null // NOTE: 10%以下は表示しない
    }
    return percentage
  },
  color: '#434646',
  backgroundColor: 'rgba(255, 255, 255, .8)',
  borderRadius: 4,
  font: {
    // NOTE: family -> defaultFontFamily
    weight: 'bold'
  }
});

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const A2 = {
  common: {
    init: () => {
      // password Toggle
      const passwordToggleEls = document.querySelectorAll('[data-password-toggle]')
      for (let i = 0; i < passwordToggleEls.length; i++) {
        passwordToggleEls[i].addEventListener('click', () => {
          const id = passwordToggleEls[i].dataset.passwordToggle
          const target = document.getElementById(id)

          switch (target.type) {
            case 'password':
              target.type = 'text'
              break
            case 'text':
              target.type = 'password'
              break
            default:
              break
          }
        })
      }
    }, // init
  },
  home: {
    init: () => {}, // init
  },
  targets: {
    init: () => {}, // init
    new: () => {
      const targetData = new TargetData()
      targetData.init()

      // NOTE: edge auto height
      const separatorBasicEl = document.querySelector('[data-separator-height="basic"]')
      const separatorItemEl = document.querySelector('[data-separator-height="item"]')
      const separatorTargetEls = document.querySelectorAll('[data-separator-height="target"]')

      // NOTE: separatorBasicEl.offsetHeight の値を切り上げ
      const roundedBasicHeight = Math.ceil(separatorBasicEl.offsetHeight)

      // NOTE: separatorItemEl.offsetHeight の値を切り捨て
      const flooredItemHeight = Math.floor(separatorItemEl.offsetHeight)

      // NOTE: margin 64px
      // NOTE: adjust +1 => https://developer.mozilla.org/ja/docs/Web/API/HTMLElement/offsetWidth
      const height = roundedBasicHeight - flooredItemHeight + 64 + 1
      for (let i = 0; i < separatorTargetEls.length; i++) {
        separatorTargetEls[i].style.height = `${height}px`
        // console.dir(separatorTargetEls[i].style.height)
      }
    },
    edit: () => {
      const targetData = new TargetData()
      targetData.init()

      // NOTE: edge auto height
      const separatorBasicEl = document.querySelector('[data-separator-height="basic"]')
      const separatorItemEl = document.querySelector('[data-separator-height="item"]')
      const separatorTargetEls = document.querySelectorAll('[data-separator-height="target"]')

      // NOTE: separatorBasicEl.offsetHeight の値を切り上げ
      const roundedBasicHeight = Math.ceil(separatorBasicEl.offsetHeight)

      // NOTE: separatorItemEl.offsetHeight の値を切り捨て
      const flooredItemHeight = Math.floor(separatorItemEl.offsetHeight)

      // NOTE: margin 64px
      // NOTE: adjust +1 => https://developer.mozilla.org/ja/docs/Web/API/HTMLElement/offsetWidth
      const height = roundedBasicHeight - flooredItemHeight + 64 + 1
      for (let i = 0; i < separatorTargetEls.length; i++) {
        separatorTargetEls[i].style.height = `${height}px`
        // console.dir(separatorTargetEls[i].style.height)
      }
    },
    update: () => {
      const targetData = new TargetData()
      targetData.init()

      // NOTE: edge auto height
      const separatorBasicEl = document.querySelector('[data-separator-height="basic"]')
      const separatorItemEl = document.querySelector('[data-separator-height="item"]')
      const separatorTargetEls = document.querySelectorAll('[data-separator-height="target"]')

      // NOTE: separatorBasicEl.offsetHeight の値を切り上げ
      const roundedBasicHeight = Math.ceil(separatorBasicEl.offsetHeight)

      // NOTE: separatorItemEl.offsetHeight の値を切り捨て
      const flooredItemHeight = Math.floor(separatorItemEl.offsetHeight)

      // NOTE: margin 64px
      // NOTE: adjust +1 => https://developer.mozilla.org/ja/docs/Web/API/HTMLElement/offsetWidth
      const height = roundedBasicHeight - flooredItemHeight + 64 + 1
      for (let i = 0; i < separatorTargetEls.length; i++) {
        separatorTargetEls[i].style.height = `${height}px`
        // console.dir(separatorTargetEls[i].style.height)
      }
    }
  },
}

const UTIL = {
  exec: (controller, action) => {
    const namespace = A2
    action = (typeof action === 'undefined') ? 'init' : action

    if (controller !== '' && namespace[controller] && typeof namespace[controller][action] === 'function') {
      namespace[controller][action]()
    }
  },

  init: () => {
    const body = document.body
    const controller = body.getAttribute('data-controller')
    const action = body.getAttribute('data-action')
    console.log(`${controller}#${action} executed`)

    UTIL.exec('common')
    UTIL.exec(controller)
    UTIL.exec(controller, action)
  }
}

document.addEventListener('turbolinks:load', UTIL.init)
document.addEventListener('turbolinks:before-cache', () => {
  const loadingEl = document.getElementById('loading')
  loadingEl.dataset.toggleFade = ''
})
