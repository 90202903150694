import Base from "./base.js"

class Snackbar extends Base {
  constructor(element) {
    super()

    this.SNACKBAR_DISPLAY_TIME_DEFAULT = 5000
    this.el = element
    this.initialize()
  }
  initialize() {
  }
  hide() {
    setTimeout( () => {
      this.el.dataset.toggleFade = ''
    }, this.SNACKBAR_DISPLAY_TIME_DEFAULT)
  }
}

export default Snackbar
