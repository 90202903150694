export default class TargetData {
  constructor() {
    // NOTE: DOM定義
    this.conversionsSumEl = document.getElementById('target-conversions-sum')
    this.conversionsBasicEl = document.getElementById('target-conversions-basic')
    this.conversionsAdEl = document.getElementById('target-conversions-ad')

    // NOTE: 基礎体力と広告の内訳合計DOM
    this.sessionsEl = document.getElementById('target-s')
    this.cvrEl = document.getElementById('target-cvr')
    this.basicSessionsEl = document.getElementById('target-basic-s')
    this.basicCvrEl = document.getElementById('target-basic-cvr')
    this.adSessionsEl = document.getElementById('target-ad-s')
    this.adCvrEl = document.getElementById('target-ad-cvr')

    // NOTE: オーガニック検索
    this.sessionsOrganicSEl = document.getElementById('target-sessions-organic-s')
    this.sessionsOrganicCvrEl = document.getElementById('target-sessions-organic-cvr')
    this.sessionsOrganicCvEl = document.getElementById('target-sessions-organic-cv')
    this.sessionsOrganicSUpEl = document.getElementById('target-sessions-organic-s-up')
    this.sessionsOrganicSDownEl = document.getElementById('target-sessions-organic-s-down')
    this.sessionsOrganicCvrUpEl = document.getElementById('target-sessions-organic-cvr-up')
    this.sessionsOrganicCvrDownEl = document.getElementById('target-sessions-organic-cvr-down')

    // NOTE: オーガニックソーシャル
    this.sessionsOrganicSocialSEl = document.getElementById('target-sessions-organic_social-s')
    this.sessionsOrganicSocialCvrEl = document.getElementById('target-sessions-organic_social-cvr')
    this.sessionsOrganicSocialCvEl = document.getElementById('target-sessions-organic_social-cv')
    this.sessionsOrganicSocialSUpEl = document.getElementById('target-sessions-organic_social-s-up')
    this.sessionsOrganicSocialSDownEl = document.getElementById('target-sessions-organic_social-s-down')
    this.sessionsOrganicSocialCvrUpEl = document.getElementById('target-sessions-organic_social-cvr-up')
    this.sessionsOrganicSocialCvrDownEl = document.getElementById('target-sessions-organic_social-cvr-down')

    // NOTE: 参照URL
    this.sessionsReferralSEl = document.getElementById('target-sessions-referral-s')
    this.sessionsReferralCvrEl = document.getElementById('target-sessions-referral-cvr')
    this.sessionsReferralCvEl = document.getElementById('target-sessions-referral-cv')
    this.sessionsReferralSUpEl = document.getElementById('target-sessions-referral-s-up')
    this.sessionsReferralSDownEl = document.getElementById('target-sessions-referral-s-down')
    this.sessionsReferralCvrUpEl = document.getElementById('target-sessions-referral-cvr-up')
    this.sessionsReferralCvrDownEl = document.getElementById('target-sessions-referral-cvr-down')

    // NOTE: ノーリファラー
    this.sessionsDirectSEl = document.getElementById('target-sessions-direct-s')
    this.sessionsDirectCvrEl = document.getElementById('target-sessions-direct-cvr')
    this.sessionsDirectCvEl = document.getElementById('target-sessions-direct-cv')
    this.sessionsDirectSUpEl = document.getElementById('target-sessions-direct-s-up')
    this.sessionsDirectSDownEl = document.getElementById('target-sessions-direct-s-down')
    this.sessionsDirectCvrUpEl = document.getElementById('target-sessions-direct-cvr-up')
    this.sessionsDirectCvrDownEl = document.getElementById('target-sessions-direct-cvr-down')

    // NOTE: メール
    this.sessionsEmailSEl = document.getElementById('target-sessions-email-s')
    this.sessionsEmailCvrEl = document.getElementById('target-sessions-email-cvr')
    this.sessionsEmailCvEl = document.getElementById('target-sessions-email-cv')
    this.sessionsEmailSUpEl = document.getElementById('target-sessions-email-s-up')
    this.sessionsEmailSDownEl = document.getElementById('target-sessions-email-s-down')
    this.sessionsEmailCvrUpEl = document.getElementById('target-sessions-email-cvr-up')
    this.sessionsEmailCvrDownEl = document.getElementById('target-sessions-email-cvr-down')

    // NOTE: 有料検索
    this.sessionsPaidSearchSEl = document.getElementById('target-sessions-paid_search-s')
    this.sessionsPaidSearchCvrEl = document.getElementById('target-sessions-paid_search-cvr')
    this.sessionsPaidSearchCvEl = document.getElementById('target-sessions-paid_search-cv')
    this.sessionsPaidSearchCpcEl = document.getElementById('target-sessions-paid_search-cpc')
    this.sessionsPaidSearchCostEl = document.getElementById('target-sessions-paid_search-cost')
    this.sessionsPaidSearchSUpEl = document.getElementById('target-sessions-paid_search-s-up')
    this.sessionsPaidSearchSDownEl = document.getElementById('target-sessions-paid_search-s-down')
    this.sessionsPaidSearchCvrUpEl = document.getElementById('target-sessions-paid_search-cvr-up')
    this.sessionsPaidSearchCvrDownEl = document.getElementById('target-sessions-paid_search-cvr-down')
    this.sessionsPaidSearchCpcUpEl = document.getElementById('target-sessions-paid_search-cpc-up')
    this.sessionsPaidSearchCpcDownEl = document.getElementById('target-sessions-paid_search-cpc-down')

    // NOTE: 有料ソーシャル
    this.sessionsPaidSocialSEl = document.getElementById('target-sessions-paid_social-s')
    this.sessionsPaidSocialCvrEl = document.getElementById('target-sessions-paid_social-cvr')
    this.sessionsPaidSocialCvEl = document.getElementById('target-sessions-paid_social-cv')
    this.sessionsPaidSocialCpcEl = document.getElementById('target-sessions-paid_social-cpc')
    this.sessionsPaidSocialCostEl = document.getElementById('target-sessions-paid_social-cost')
    this.sessionsPaidSocialSUpEl = document.getElementById('target-sessions-paid_social-s-up')
    this.sessionsPaidSocialSDownEl = document.getElementById('target-sessions-paid_social-s-down')
    this.sessionsPaidSocialCvrUpEl = document.getElementById('target-sessions-paid_social-cvr-up')
    this.sessionsPaidSocialCvrDownEl = document.getElementById('target-sessions-paid_social-cvr-down')
    this.sessionsPaidSocialCpcUpEl = document.getElementById('target-sessions-paid_social-cpc-up')
    this.sessionsPaidSocialCpcDownEl = document.getElementById('target-sessions-paid_social-cpc-down')

    // NOTE: ディスプレイ
    this.sessionsDisplaySEl = document.getElementById('target-sessions-display-s')
    this.sessionsDisplayCvrEl = document.getElementById('target-sessions-display-cvr')
    this.sessionsDisplayCvEl = document.getElementById('target-sessions-display-cv')
    this.sessionsDisplayCpcEl = document.getElementById('target-sessions-display-cpc')
    this.sessionsDisplayCostEl = document.getElementById('target-sessions-display-cost')
    this.sessionsDisplaySUpEl = document.getElementById('target-sessions-display-s-up')
    this.sessionsDisplaySDownEl = document.getElementById('target-sessions-display-s-down')
    this.sessionsDisplayCvrUpEl = document.getElementById('target-sessions-display-cvr-up')
    this.sessionsDisplayCvrDownEl = document.getElementById('target-sessions-display-cvr-down')
    this.sessionsDisplayCpcUpEl = document.getElementById('target-sessions-display-cpc-up')
    this.sessionsDisplayCpcDownEl = document.getElementById('target-sessions-display-cpc-down')

    // NOTE: 有料動画
    this.sessionsVideoSEl = document.getElementById('target-sessions-video-s')
    this.sessionsVideoCvrEl = document.getElementById('target-sessions-video-cvr')
    this.sessionsVideoCvEl = document.getElementById('target-sessions-video-cv')
    this.sessionsVideoCpcEl = document.getElementById('target-sessions-video-cpc')
    this.sessionsVideoCostEl = document.getElementById('target-sessions-video-cost')
    this.sessionsVideoSUpEl = document.getElementById('target-sessions-video-s-up')
    this.sessionsVideoSDownEl = document.getElementById('target-sessions-video-s-down')
    this.sessionsVideoCvrUpEl = document.getElementById('target-sessions-video-cvr-up')
    this.sessionsVideoCvrDownEl = document.getElementById('target-sessions-video-cvr-down')
    this.sessionsVideoCpcUpEl = document.getElementById('target-sessions-video-cpc-up')
    this.sessionsVideoCpcDownEl = document.getElementById('target-sessions-video-cpc-down')

    // NOTE: アフィリエイト
    this.sessionsAffiliateSEl = document.getElementById('target-sessions-affiliate-s')
    this.sessionsAffiliateCvrEl = document.getElementById('target-sessions-affiliate-cvr')
    this.sessionsAffiliateCvEl = document.getElementById('target-sessions-affiliate-cv')
    this.sessionsAffiliateCpcEl = document.getElementById('target-sessions-affiliate-cpc')
    this.sessionsAffiliateCostEl = document.getElementById('target-sessions-affiliate-cost')
    this.sessionsAffiliateSUpEl = document.getElementById('target-sessions-affiliate-s-up')
    this.sessionsAffiliateSDownEl = document.getElementById('target-sessions-affiliate-s-down')
    this.sessionsAffiliateCvrUpEl = document.getElementById('target-sessions-affiliate-cvr-up')
    this.sessionsAffiliateCvrDownEl = document.getElementById('target-sessions-affiliate-cvr-down')
    this.sessionsAffiliateCpcUpEl = document.getElementById('target-sessions-affiliate-cpc-up')
    this.sessionsAffiliateCpcDownEl = document.getElementById('target-sessions-affiliate-cpc-down')

    // NOTE: 流入数増加ボタン
    this.cvIncrease5per = document.getElementById('target-cv-increase-5per')

    // NOTE: 広告費合計
    this.adCostSumEl = document.getElementById('target-ad-cost-sum')

    // NOTE: 初期値セット処理
    this.conversions = {
      sum: window.targetDataJsonObj.hasOwnProperty('conversions') && window.targetDataJsonObj['conversions'].hasOwnProperty('t') ? window.targetDataJsonObj['conversions']['t'] : '0',           // NOTE: 合計
      basic: window.targetDataJsonObj.hasOwnProperty('conversions') && window.targetDataJsonObj['conversions'].hasOwnProperty('basic') ? window.targetDataJsonObj['conversions']['basic'] : '0', // NOTE: 基礎体力
      ad: window.targetDataJsonObj.hasOwnProperty('conversions') && window.targetDataJsonObj['conversions'].hasOwnProperty('ad') ? window.targetDataJsonObj['conversions']['ad'] : '0'           // NOTE: 広告
    }
    this.breakdown = {
      all: {
        s: window.targetDataJsonObj.hasOwnProperty('sessions') && window.targetDataJsonObj['sessions'].hasOwnProperty('t') ? window.targetDataJsonObj['sessions']['t'] : '0',
        cvr: window.targetDataJsonObj.hasOwnProperty('conversion_rate') && window.targetDataJsonObj['conversion_rate'].hasOwnProperty('t') ? window.targetDataJsonObj['conversion_rate']['t'] : '0.0'
      },
      basic: {
        s: window.targetDataJsonObj.hasOwnProperty('sessions') && window.targetDataJsonObj['sessions'].hasOwnProperty('basic') ? window.targetDataJsonObj['sessions']['basic'] : '0',
        cvr: window.targetDataJsonObj.hasOwnProperty('conversion_rate') && window.targetDataJsonObj['conversion_rate'].hasOwnProperty('basic') ? window.targetDataJsonObj['conversion_rate']['basic'] : '0.0'
      },
      ad: {
        s: window.targetDataJsonObj.hasOwnProperty('sessions') && window.targetDataJsonObj['sessions'].hasOwnProperty('ad') ? window.targetDataJsonObj['sessions']['ad'] : '0',
        cvr: window.targetDataJsonObj.hasOwnProperty('conversion_rate') && window.targetDataJsonObj['conversion_rate'].hasOwnProperty('ad') ? window.targetDataJsonObj['conversion_rate']['ad'] : '0.0'
      }
    }
    this.sessions = {
      // NOTE: 基礎体力
      organic: {
        s: window.targetDataJsonObj.hasOwnProperty('organic') && window.targetDataJsonObj['organic'].hasOwnProperty('t') ? window.targetDataJsonObj['organic']['t'] : '0',
        cvr: window.targetDataJsonObj.hasOwnProperty('organic') && window.targetDataJsonObj['organic'].hasOwnProperty('tcvr') ? window.targetDataJsonObj['organic']['tcvr'] : '0.0',
        cv: window.targetDataJsonObj.hasOwnProperty('organic') && window.targetDataJsonObj['organic'].hasOwnProperty('tcv') ? window.targetDataJsonObj['organic']['tcv'] : '0'
      },
      organic_social: {
        s: window.targetDataJsonObj.hasOwnProperty('organic_social') && window.targetDataJsonObj['organic_social'].hasOwnProperty('t') ? window.targetDataJsonObj['organic_social']['t'] : '0',
        cvr: window.targetDataJsonObj.hasOwnProperty('organic_social') && window.targetDataJsonObj['organic_social'].hasOwnProperty('tcvr') ? window.targetDataJsonObj['organic_social']['tcvr'] : '0.0',
        cv: window.targetDataJsonObj.hasOwnProperty('organic_social') && window.targetDataJsonObj['organic_social'].hasOwnProperty('tcv') ? window.targetDataJsonObj['organic_social']['tcv'] : '0'
      },
      referral: {
        s: window.targetDataJsonObj.hasOwnProperty('referral') && window.targetDataJsonObj['referral'].hasOwnProperty('t') ? window.targetDataJsonObj['referral']['t'] : '0',
        cvr: window.targetDataJsonObj.hasOwnProperty('referral') && window.targetDataJsonObj['referral'].hasOwnProperty('tcvr') ? window.targetDataJsonObj['referral']['tcvr'] : '0.0',
        cv: window.targetDataJsonObj.hasOwnProperty('referral') && window.targetDataJsonObj['referral'].hasOwnProperty('tcv') ? window.targetDataJsonObj['referral']['tcv'] : '0'
      },
      direct: {
        s: window.targetDataJsonObj.hasOwnProperty('direct') && window.targetDataJsonObj['direct'].hasOwnProperty('t') ? window.targetDataJsonObj['direct']['t'] : '0',
        cvr: window.targetDataJsonObj.hasOwnProperty('direct') && window.targetDataJsonObj['direct'].hasOwnProperty('tcvr') ? window.targetDataJsonObj['direct']['tcvr'] : '0.0',
        cv: window.targetDataJsonObj.hasOwnProperty('direct') && window.targetDataJsonObj['direct'].hasOwnProperty('tcv') ? window.targetDataJsonObj['direct']['tcv'] : '0'
      },
      email: {
        s: window.targetDataJsonObj.hasOwnProperty('email') && window.targetDataJsonObj['email'].hasOwnProperty('t') ? window.targetDataJsonObj['email']['t'] : '0',
        cvr: window.targetDataJsonObj.hasOwnProperty('email') && window.targetDataJsonObj['email'].hasOwnProperty('tcvr') ? window.targetDataJsonObj['email']['tcvr'] : '0.0',
        cv: window.targetDataJsonObj.hasOwnProperty('email') && window.targetDataJsonObj['email'].hasOwnProperty('tcv') ? window.targetDataJsonObj['email']['tcv'] : '0'
      },
      // NOTE: 広告
      paid_search: {
        s: window.targetDataJsonObj.hasOwnProperty('paid_search') && window.targetDataJsonObj['paid_search'].hasOwnProperty('t') ? window.targetDataJsonObj['paid_search']['t'] : '0',
        cvr: window.targetDataJsonObj.hasOwnProperty('paid_search') && window.targetDataJsonObj['paid_search'].hasOwnProperty('tcvr') ? window.targetDataJsonObj['paid_search']['tcvr'] : '0.0',
        cv: window.targetDataJsonObj.hasOwnProperty('paid_search') && window.targetDataJsonObj['paid_search'].hasOwnProperty('tcv') ? window.targetDataJsonObj['paid_search']['tcv'] : '0',
        cpc: window.targetDataJsonObj.hasOwnProperty('paid_search') && window.targetDataJsonObj['paid_search'].hasOwnProperty('cpc') ? window.targetDataJsonObj['paid_search']['cpc'] : '0',
        cost: window.targetDataJsonObj.hasOwnProperty('paid_search') && window.targetDataJsonObj['paid_search'].hasOwnProperty('cost') ? window.targetDataJsonObj['paid_search']['cost'] : '0'
      },
      paid_social: {
        s: window.targetDataJsonObj.hasOwnProperty('paid_social') && window.targetDataJsonObj['paid_social'].hasOwnProperty('t') ? window.targetDataJsonObj['paid_social']['t'] : '0',
        cvr: window.targetDataJsonObj.hasOwnProperty('paid_social') && window.targetDataJsonObj['paid_social'].hasOwnProperty('tcvr') ? window.targetDataJsonObj['paid_social']['tcvr'] : '0.0',
        cv: window.targetDataJsonObj.hasOwnProperty('paid_social') && window.targetDataJsonObj['paid_social'].hasOwnProperty('tcv') ? window.targetDataJsonObj['paid_social']['tcv'] : '0',
        cpc: window.targetDataJsonObj.hasOwnProperty('paid_social') && window.targetDataJsonObj['paid_social'].hasOwnProperty('cpc') ? window.targetDataJsonObj['paid_social']['cpc'] : '0',
        cost: window.targetDataJsonObj.hasOwnProperty('paid_social') && window.targetDataJsonObj['paid_social'].hasOwnProperty('cost') ? window.targetDataJsonObj['paid_social']['cost'] : '0'
      },
      display: {
        s: window.targetDataJsonObj.hasOwnProperty('display') && window.targetDataJsonObj['display'].hasOwnProperty('t') ? window.targetDataJsonObj['display']['t'] : '0',
        cvr: window.targetDataJsonObj.hasOwnProperty('display') && window.targetDataJsonObj['display'].hasOwnProperty('tcvr') ? window.targetDataJsonObj['display']['tcvr'] : '0.0',
        cv: window.targetDataJsonObj.hasOwnProperty('display') && window.targetDataJsonObj['display'].hasOwnProperty('tcv') ? window.targetDataJsonObj['display']['tcv'] : '0',
        cpc: window.targetDataJsonObj.hasOwnProperty('display') && window.targetDataJsonObj['display'].hasOwnProperty('cpc') ? window.targetDataJsonObj['display']['cpc'] : '0',
        cost: window.targetDataJsonObj.hasOwnProperty('display') && window.targetDataJsonObj['display'].hasOwnProperty('cost') ? window.targetDataJsonObj['display']['cost'] : '0'
      },
      video: {
        s: window.targetDataJsonObj.hasOwnProperty('video') && window.targetDataJsonObj['video'].hasOwnProperty('t') ? window.targetDataJsonObj['video']['t'] : '0',
        cvr: window.targetDataJsonObj.hasOwnProperty('video') && window.targetDataJsonObj['video'].hasOwnProperty('tcvr') ? window.targetDataJsonObj['video']['tcvr'] : '0.0',
        cv: window.targetDataJsonObj.hasOwnProperty('video') && window.targetDataJsonObj['video'].hasOwnProperty('tcv') ? window.targetDataJsonObj['video']['tcv'] : '0',
        cpc: window.targetDataJsonObj.hasOwnProperty('video') && window.targetDataJsonObj['video'].hasOwnProperty('cpc') ? window.targetDataJsonObj['video']['cpc'] : '0',
        cost: window.targetDataJsonObj.hasOwnProperty('video') && window.targetDataJsonObj['video'].hasOwnProperty('cost') ? window.targetDataJsonObj['video']['cost'] : '0'
      },
      affiliate: {
        s: window.targetDataJsonObj.hasOwnProperty('affiliate') && window.targetDataJsonObj['affiliate'].hasOwnProperty('t') ? window.targetDataJsonObj['affiliate']['t'] : '0',
        cvr: window.targetDataJsonObj.hasOwnProperty('affiliate') && window.targetDataJsonObj['affiliate'].hasOwnProperty('tcvr') ? window.targetDataJsonObj['affiliate']['tcvr'] : '0.0',
        cv: window.targetDataJsonObj.hasOwnProperty('affiliate') && window.targetDataJsonObj['affiliate'].hasOwnProperty('tcv') ? window.targetDataJsonObj['affiliate']['tcv'] : '0',
        cpc: window.targetDataJsonObj.hasOwnProperty('affiliate') && window.targetDataJsonObj['affiliate'].hasOwnProperty('cpc') ? window.targetDataJsonObj['affiliate']['cpc'] : '0',
        cost: window.targetDataJsonObj.hasOwnProperty('affiliate') && window.targetDataJsonObj['affiliate'].hasOwnProperty('cost') ? window.targetDataJsonObj['affiliate']['cost'] : '0'
      },
    }

    // NOTE: dataset buttons
    this.datasetSample01El = document.getElementById('target-dataset-sample-01')
    this.conversionsSample01 = {
      sum: '20',   // NOTE: 合計
      basic: '20', // NOTE: 基礎体力
      ad: '0'     // NOTE: 広告
    }
    this.breakdownSample01 = {
      all: {
        s: '2100',
        cvr: '1.0',
      },
      basic: {
        s: '2100',
        cvr: '1.0',
      },
      ad: {
        s: '0',
        cvr: '0.0',
      }
    }
    this.sessionsSample01 = {
      // NOTE: 基礎体力
      organic: {
        s: '1500',
        cvr: '1.0',
        cv: '15'
      },
      organic_social: {
        s: '200',
        cvr: '0.5',
        cv: '1'
      },
      referral: {
        s: '300',
        cvr: '0.5',
        cv: '2'
      },
      direct: {
        s: '100',
        cvr: '1.5',
        cv: '2'
      },
      email: {
        s: '0',
        cvr: '0.0',
        cv: '0'
      },
      // NOTE: 広告
      paid_search: {
        s: '0',
        cvr: '0.0',
        cv: '0',
        cpc: '0',
        cost: '0'
      },
      paid_social: {
        s: '0',
        cvr: '0.0',
        cv: '0',
        cpc: '0',
        cost: '0'
      },
      display: {
        s: '0',
        cvr: '0.0',
        cv: '0',
        cpc: '0',
        cost: '0'
      },
      video: {
        s: '0',
        cvr: '0.0',
        cv: '0',
        cpc: '0',
        cost: '0'
      },
      affiliate: {
        s: '0',
        cvr: '0.0',
        cv: '0',
        cpc: '0',
        cost: '0'
      },
    }
    this.datasetSample02El = document.getElementById('target-dataset-sample-02')
    this.conversionsSample02 = {
      sum: '30',   // NOTE: 合計
      basic: '22', // NOTE: 基礎体力
      ad: '8'     // NOTE: 広告
    }
    this.breakdownSample02 = {
      all: {
        s: '4000',
        cvr: '0.8',
      },
      basic: {
        s: '2300',
        cvr: '1.0',
      },
      ad: {
        s: '1700',
        cvr: '0.5',
      }
    }
    this.sessionsSample02 = {
      // NOTE: 基礎体力
      organic: {
        s: '1500',
        cvr: '1.0',
        cv: '15'
      },
      organic_social: {
        s: '300',
        cvr: '0.5',
        cv: '2'
      },
      referral: {
        s: '300',
        cvr: '0.5',
        cv: '2'
      },
      direct: {
        s: '200',
        cvr: '1.5',
        cv: '3'
      },
      email: {
        s: '0',
        cvr: '0.0',
        cv: '0'
      },
      // NOTE: 広告
      paid_search: {
        s: '500',
        cvr: '0.8',
        cv: '4',
        cpc: '80',
        cost: '40000'
      },
      paid_social: {
        s: '200',
        cvr: '0.5',
        cv: '1',
        cpc: '50',
        cost: '10000'
      },
      display: {
        s: '1000',
        cvr: '0.3',
        cv: '3',
        cpc: '30',
        cost: '30000'
      },
      video: {
        s: '0',
        cvr: '0.0',
        cv: '0',
        cpc: '0',
        cost: '0'
      },
      affiliate: {
        s: '0',
        cvr: '0.0',
        cv: '0',
        cpc: '0',
        cost: '0'
      },
    }
    this.datasetSample03El = document.getElementById('target-dataset-sample-03')
    this.conversionsSample03 = {
      sum: '59',   // NOTE: 合計
      basic: '59', // NOTE: 基礎体力
      ad: '0'     // NOTE: 広告
    }
    this.breakdownSample03 = {
      all: {
        s: '5200',
        cvr: '1.1',
      },
      basic: {
        s: '5200',
        cvr: '1.1',
      },
      ad: {
        s: '0',
        cvr: '0.0',
      }
    }
    this.sessionsSample03 = {
      // NOTE: 基礎体力
      organic: {
        s: '3000',
        cvr: '1.5',
        cv: '45'
      },
      organic_social: {
        s: '500',
        cvr: '0.5',
        cv: '3'
      },
      referral: {
        s: '1500',
        cvr: '0.5',
        cv: '8'
      },
      direct: {
        s: '200',
        cvr: '1.5',
        cv: '3'
      },
      email: {
        s: '0',
        cvr: '0.0',
        cv: '0'
      },
      // NOTE: 広告
      paid_search: {
        s: '0',
        cvr: '0.0',
        cv: '0',
        cpc: '0',
        cost: '0'
      },
      paid_social: {
        s: '0',
        cvr: '0.0',
        cv: '0',
        cpc: '0',
        cost: '0'
      },
      display: {
        s: '0',
        cvr: '0.0',
        cv: '0',
        cpc: '0',
        cost: '0'
      },
      video: {
        s: '0',
        cvr: '0.0',
        cv: '0',
        cpc: '0',
        cost: '0'
      },
      affiliate: {
        s: '0',
        cvr: '0.0',
        cv: '0',
        cpc: '0',
        cost: '0'
      },
    }
    this.datasetSample04El = document.getElementById('target-dataset-sample-04')
    this.conversionsSample04 = {
      sum: '75',   // NOTE: 合計
      basic: '59', // NOTE: 基礎体力
      ad: '16'     // NOTE: 広告
    }
    this.breakdownSample04 = {
      all: {
        s: '8100',
        cvr: '0.9',
      },
      basic: {
        s: '5200',
        cvr: '1.1',
      },
      ad: {
        s: '2900',
        cvr: '0.6',
      }
    }
    this.sessionsSample04 = {
      // NOTE: 基礎体力
      organic: {
        s: '3000',
        cvr: '1.5',
        cv: '45'
      },
      organic_social: {
        s: '500',
        cvr: '0.5',
        cv: '3'
      },
      referral: {
        s: '1500',
        cvr: '0.5',
        cv: '8'
      },
      direct: {
        s: '200',
        cvr: '1.5',
        cv: '3'
      },
      email: {
        s: '0',
        cvr: '0.0',
        cv: '0'
      },
      // NOTE: 広告
      paid_search: {
        s: '600',
        cvr: '0.8',
        cv: '5',
        cpc: '80',
        cost: '48000'
      },
      paid_social: {
        s: '1000',
        cvr: '0.5',
        cv: '5',
        cpc: '40',
        cost: '40000'
      },
      display: {
        s: '1000',
        cvr: '0.3',
        cv: '3',
        cpc: '30',
        cost: '30000'
      },
      video: {
        s: '300',
        cvr: '1.0',
        cv: '3',
        cpc: '100',
        cost: '30000'
      },
      affiliate: {
        s: '0',
        cvr: '0.0',
        cv: '0',
        cpc: '0',
        cost: '0'
      },
    }
    this.datasetResetEl = document.getElementById('target-dataset-reset')
    this.conversionsInit = _.cloneDeep(this.conversions)
    this.sessionsInit = _.cloneDeep(this.sessions)
    this.breakdownInit = _.cloneDeep(this.breakdown)
  }

  init() {
    this.updateDisplayData()

    // NOTE: オーガニック検索 Sessions
    this.sessionsOrganicSEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsOrganicSEl.classList.add('has-error')
        return
      }
      this.sessions.organic.s = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsOrganicSUpEl.addEventListener('click', () => {
      this.sessions.organic.s =  Number(this.sessions.organic.s) + 500
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsOrganicSDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.organic.s) - 500) < 0) {
        this.sessions.organic.s =  0
      } else {
        this.sessions.organic.s = Number(this.sessions.organic.s) - 500
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    // NOTE: オーガニック検索 CVR
    this.sessionsOrganicCvrEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsOrganicCvrEl.classList.add('has-error')
        return
      }
      this.sessions.organic.cvr = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsOrganicCvrUpEl.addEventListener('click', () => {
      this.sessions.organic.cvr =  Number(this.sessions.organic.cvr) + 0.1
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsOrganicCvrDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.organic.cvr) - 0.1) < 0) {
        this.sessions.organic.cvr =  0
      } else {
        this.sessions.organic.cvr = Number(this.sessions.organic.cvr) - 0.1
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })

    // NOTE: オーガニックソーシャル Sessions
    this.sessionsOrganicSocialSEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsOrganicSocialSEl.classList.add('has-error')
        return
      }
      this.sessions.organic_social.s = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsOrganicSocialSUpEl.addEventListener('click', () => {
      this.sessions.organic_social.s =  Number(this.sessions.organic_social.s) + 500
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsOrganicSocialSDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.organic_social.s) - 500) < 0) {
        this.sessions.organic_social.s =  0
      } else {
        this.sessions.organic_social.s = Number(this.sessions.organic_social.s) - 500
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    // NOTE: オーガニックソーシャル CVR
    this.sessionsOrganicSocialCvrEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsOrganicSocialCvrEl.classList.add('has-error')
        return
      }
      this.sessions.organic_social.cvr = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsOrganicSocialCvrUpEl.addEventListener('click', () => {
      this.sessions.organic_social.cvr =  Number(this.sessions.organic_social.cvr) + 0.1
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsOrganicSocialCvrDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.organic_social.cvr) - 0.1) < 0) {
        this.sessions.organic_social.cvr =  0
      } else {
        this.sessions.organic_social.cvr = Number(this.sessions.organic_social.cvr) - 0.1
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })

    // NOTE: 参照URL Sessions
    this.sessionsReferralSEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsReferralSEl.classList.add('has-error')
        return
      }
      this.sessions.referral.s = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsReferralSUpEl.addEventListener('click', () => {
      this.sessions.referral.s =  Number(this.sessions.referral.s) + 500
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsReferralSDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.referral.s) - 500) < 0) {
        this.sessions.referral.s =  0
      } else {
        this.sessions.referral.s = Number(this.sessions.referral.s) - 500
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    // NOTE: 参照URL CVR
    this.sessionsReferralCvrEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsReferralCvrEl.classList.add('has-error')
        return
      }
      this.sessions.referral.cvr = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsReferralCvrUpEl.addEventListener('click', () => {
      this.sessions.referral.cvr =  Number(this.sessions.referral.cvr) + 0.1
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsReferralCvrDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.referral.cvr) - 0.1) < 0) {
        this.sessions.referral.cvr =  0
      } else {
        this.sessions.referral.cvr = Number(this.sessions.referral.cvr) - 0.1
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })

    // NOTE: ノーリファラー Sessions
    this.sessionsDirectSEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsDirectSEl.classList.add('has-error')
        return
      }
      this.sessions.direct.s = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsDirectSUpEl.addEventListener('click', () => {
      this.sessions.direct.s =  Number(this.sessions.direct.s) + 500
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsDirectSDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.direct.s) - 500) < 0) {
        this.sessions.direct.s =  0
      } else {
        this.sessions.direct.s = Number(this.sessions.direct.s) - 500
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    // NOTE: ノーリファラー CVR
    this.sessionsDirectCvrEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsDirectCvrEl.classList.add('has-error')
        return
      }
      this.sessions.direct.cvr = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsDirectCvrUpEl.addEventListener('click', () => {
      this.sessions.direct.cvr =  Number(this.sessions.direct.cvr) + 0.1
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsDirectCvrDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.direct.cvr) - 0.1) < 0) {
        this.sessions.direct.cvr =  0
      } else {
        this.sessions.direct.cvr = Number(this.sessions.direct.cvr) - 0.1
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })

    // NOTE: メール Sessions
    this.sessionsEmailSEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsEmailSEl.classList.add('has-error')
        return
      }
      this.sessions.email.s = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsEmailSUpEl.addEventListener('click', () => {
      this.sessions.email.s =  Number(this.sessions.email.s) + 500
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsEmailSDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.email.s) - 500) < 0) {
        this.sessions.email.s =  0
      } else {
        this.sessions.email.s = Number(this.sessions.email.s) - 500
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    // NOTE: メール CVR
    this.sessionsEmailCvrEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsEmailCvrEl.classList.add('has-error')
        return
      }
      this.sessions.email.cvr = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsEmailCvrUpEl.addEventListener('click', () => {
      this.sessions.email.cvr =  Number(this.sessions.email.cvr) + 0.1
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsEmailCvrDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.email.cvr) - 0.1) < 0) {
        this.sessions.email.cvr =  0
      } else {
        this.sessions.email.cvr = Number(this.sessions.email.cvr) - 0.1
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })

    // NOTE: 有料検索 Sessions
    this.sessionsPaidSearchSEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsPaidSearchSEl.classList.add('has-error')
        return
      }
      this.sessions.paid_search.s = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsPaidSearchSUpEl.addEventListener('click', () => {
      this.sessions.paid_search.s =  Number(this.sessions.paid_search.s) + 500
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsPaidSearchSDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.paid_search.s) - 500) < 0) {
        this.sessions.paid_search.s =  0
      } else {
        this.sessions.paid_search.s = Number(this.sessions.paid_search.s) - 500
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    // NOTE: 有料検索 CVR
    this.sessionsPaidSearchCvrEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsPaidSearchCvrEl.classList.add('has-error')
        return
      }
      this.sessions.paid_search.cvr = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsPaidSearchCvrUpEl.addEventListener('click', () => {
      this.sessions.paid_search.cvr =  Number(this.sessions.paid_search.cvr) + 0.1
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsPaidSearchCvrDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.paid_search.cvr) - 0.1) < 0) {
        this.sessions.paid_search.cvr =  0
      } else {
        this.sessions.paid_search.cvr = Number(this.sessions.paid_search.cvr) - 0.1
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    // NOTE: 有料検索 CPC
    this.sessionsPaidSearchCpcEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsPaidSearchCpcEl.classList.add('has-error')
        return
      }
      this.sessions.paid_search.cpc = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsPaidSearchCpcUpEl.addEventListener('click', () => {
      this.sessions.paid_search.cpc =  Number(this.sessions.paid_search.cpc) + 10
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsPaidSearchCpcDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.paid_search.cpc) - 10) < 0) {
        this.sessions.paid_search.cpc =  0
      } else {
        this.sessions.paid_search.cpc = Number(this.sessions.paid_search.cpc) - 10
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })

    // NOTE: 有料ソーシャル Sessions
    this.sessionsPaidSocialSEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsPaidSocialSEl.classList.add('has-error')
        return
      }
      this.sessions.paid_social.s = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsPaidSocialSUpEl.addEventListener('click', () => {
      this.sessions.paid_social.s =  Number(this.sessions.paid_social.s) + 500
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsPaidSocialSDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.paid_social.s) - 500) < 0) {
        this.sessions.paid_social.s =  0
      } else {
        this.sessions.paid_social.s = Number(this.sessions.paid_social.s) - 500
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    // NOTE: 有料ソーシャル CVR
    this.sessionsPaidSocialCvrEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsPaidSocialCvrEl.classList.add('has-error')
        return
      }
      this.sessions.paid_social.cvr = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsPaidSocialCvrUpEl.addEventListener('click', () => {
      this.sessions.paid_social.cvr =  Number(this.sessions.paid_social.cvr) + 0.1
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsPaidSocialCvrDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.paid_social.cvr) - 0.1) < 0) {
        this.sessions.paid_social.cvr =  0
      } else {
        this.sessions.paid_social.cvr = Number(this.sessions.paid_social.cvr) - 0.1
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    // NOTE: 有料ソーシャル CPC
    this.sessionsPaidSocialCpcEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsPaidSocialCpcEl.classList.add('has-error')
        return
      }
      this.sessions.paid_social.cpc = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsPaidSocialCpcUpEl.addEventListener('click', () => {
      this.sessions.paid_social.cpc =  Number(this.sessions.paid_social.cpc) + 10
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsPaidSocialCpcDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.paid_social.cpc) - 10) < 0) {
        this.sessions.paid_social.cpc =  0
      } else {
        this.sessions.paid_social.cpc = Number(this.sessions.paid_social.cpc) - 10
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })

    // NOTE: ディスプレイ Sessions
    this.sessionsDisplaySEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsDisplaySEl.classList.add('has-error')
        return
      }
      this.sessions.display.s = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsDisplaySUpEl.addEventListener('click', () => {
      this.sessions.display.s =  Number(this.sessions.display.s) + 500
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsDisplaySDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.display.s) - 500) < 0) {
        this.sessions.display.s =  0
      } else {
        this.sessions.display.s = Number(this.sessions.display.s) - 500
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    // NOTE: ディスプレイ CVR
    this.sessionsDisplayCvrEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsDisplayCvrEl.classList.add('has-error')
        return
      }
      this.sessions.display.cvr = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsDisplayCvrUpEl.addEventListener('click', () => {
      this.sessions.display.cvr =  Number(this.sessions.display.cvr) + 0.1
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsDisplayCvrDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.display.cvr) - 0.1) < 0) {
        this.sessions.display.cvr =  0
      } else {
        this.sessions.display.cvr = Number(this.sessions.display.cvr) - 0.1
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    // NOTE: ディスプレイ CPC
    this.sessionsDisplayCpcEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsDisplayCpcEl.classList.add('has-error')
        return
      }
      this.sessions.display.cpc = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsDisplayCpcUpEl.addEventListener('click', () => {
      this.sessions.display.cpc =  Number(this.sessions.display.cpc) + 10
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsDisplayCpcDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.display.cpc) - 10) < 0) {
        this.sessions.display.cpc =  0
      } else {
        this.sessions.display.cpc = Number(this.sessions.display.cpc) - 10
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })

    // NOTE: 有料動画 Sessions
    this.sessionsVideoSEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsVideoSEl.classList.add('has-error')
        return
      }
      this.sessions.video.s = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsVideoSUpEl.addEventListener('click', () => {
      this.sessions.video.s =  Number(this.sessions.video.s) + 500
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsVideoSDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.video.s) - 500) < 0) {
        this.sessions.video.s =  0
      } else {
        this.sessions.video.s = Number(this.sessions.video.s) - 500
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    // NOTE: 有料動画 CVR
    this.sessionsVideoCvrEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsVideoCvrEl.classList.add('has-error')
        return
      }
      this.sessions.video.cvr = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsVideoCvrUpEl.addEventListener('click', () => {
      this.sessions.video.cvr =  Number(this.sessions.video.cvr) + 0.1
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsVideoCvrDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.video.cvr) - 0.1) < 0) {
        this.sessions.video.cvr =  0
      } else {
        this.sessions.video.cvr = Number(this.sessions.video.cvr) - 0.1
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    // NOTE: 有料動画 CPC
    this.sessionsVideoCpcEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsVideoCpcEl.classList.add('has-error')
        return
      }
      this.sessions.video.cpc = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsVideoCpcUpEl.addEventListener('click', () => {
      this.sessions.video.cpc =  Number(this.sessions.video.cpc) + 10
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsVideoCpcDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.video.cpc) - 10) < 0) {
        this.sessions.video.cpc =  0
      } else {
        this.sessions.video.cpc = Number(this.sessions.video.cpc) - 10
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })

    // NOTE: アフィリエイト Sessions
    this.sessionsAffiliateSEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsAffiliateSEl.classList.add('has-error')
        return
      }
      this.sessions.affiliate.s = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsAffiliateSUpEl.addEventListener('click', () => {
      this.sessions.affiliate.s =  Number(this.sessions.affiliate.s) + 500
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsAffiliateSDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.affiliate.s) - 500) < 0) {
        this.sessions.affiliate.s =  0
      } else {
        this.sessions.affiliate.s = Number(this.sessions.affiliate.s) - 500
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    // NOTE: アフィリエイト CVR
    this.sessionsAffiliateCvrEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsAffiliateCvrEl.classList.add('has-error')
        return
      }
      this.sessions.affiliate.cvr = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsAffiliateCvrUpEl.addEventListener('click', () => {
      this.sessions.affiliate.cvr =  Number(this.sessions.affiliate.cvr) + 0.1
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsAffiliateCvrDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.affiliate.cvr) - 0.1) < 0) {
        this.sessions.affiliate.cvr =  0
      } else {
        this.sessions.affiliate.cvr = Number(this.sessions.affiliate.cvr) - 0.1
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    // NOTE: アフィリエイト CPC
    this.sessionsAffiliateCpcEl.addEventListener('change', (e) => {
      let v = e.target.value
      v = v.replaceAll(',', '')
      if (isNaN(v)) {
        this.sessionsAffiliateCpcEl.classList.add('has-error')
        return
      }
      this.sessions.affiliate.cpc = v
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsAffiliateCpcUpEl.addEventListener('click', () => {
      this.sessions.affiliate.cpc =  Number(this.sessions.affiliate.cpc) + 10
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })
    this.sessionsAffiliateCpcDownEl.addEventListener('click', () => {
      if ((Number(this.sessions.affiliate.cpc) - 10) < 0) {
        this.sessions.affiliate.cpc =  0
      } else {
        this.sessions.affiliate.cpc = Number(this.sessions.affiliate.cpc) - 10
      }
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })

    // NOTE: 流入数増加ボタン
    this.cvIncrease5per.addEventListener('click', () => {
      if (!confirm('全体の流入を 5% 増加させます、よろしいですか？')) return;

      this.sessions.organic.s = Math.round(Number(this.sessions.organic.s) * 1.05)
      this.sessions.organic_social.s =Math.round( Number(this.sessions.organic_social.s) * 1.05)
      this.sessions.referral.s =Math.round( Number(this.sessions.referral.s) * 1.05)
      this.sessions.direct.s =Math.round( Number(this.sessions.direct.s) * 1.05)
      this.sessions.email.s =Math.round( Number(this.sessions.email.s) * 1.05)
      this.updateData()
      this.updateDisplayData()
      this.resetError()
    })

    // NOTE: dataset function
    this.datasetSample01El.addEventListener('click', () => {
      if (!confirm('サンプルモデル01 のデータを反映させます、よろしいですか？')) return;

      this.conversions = _.cloneDeep(this.conversionsSample01)
      this.breakdown = _.cloneDeep(this.breakdownSample01)
      this.sessions = _.cloneDeep(this.sessionsSample01)

      this.updateDisplayData()
      this.resetError()
    })

    // NOTE: dataset function
    this.datasetSample02El.addEventListener('click', () => {
      if (!confirm('サンプルモデル02 のデータを反映させます、よろしいですか？')) return;

      this.conversions = _.cloneDeep(this.conversionsSample02)
      this.breakdown = _.cloneDeep(this.breakdownSample02)
      this.sessions = _.cloneDeep(this.sessionsSample02)
      this.updateDisplayData()
      this.resetError()
    })

    // NOTE: dataset function
    this.datasetSample03El.addEventListener('click', () => {
      if (!confirm('サンプルモデル03 のデータを反映させます、よろしいですか？')) return;

      this.conversions = _.cloneDeep(this.conversionsSample03)
      this.breakdown = _.cloneDeep(this.breakdownSample03)
      this.sessions = _.cloneDeep(this.sessionsSample03)
      this.updateDisplayData()
      this.resetError()
    })

    // NOTE: dataset function
    this.datasetSample04El.addEventListener('click', () => {
      if (!confirm('サンプルモデル04 のデータを反映させます、よろしいですか？')) return;

      this.conversions = _.cloneDeep(this.conversionsSample04)
      this.breakdown = _.cloneDeep(this.breakdownSample04)
      this.sessions = _.cloneDeep(this.sessionsSample04)
      this.updateDisplayData()
      this.resetError()
    })

    // NOTE: dataset function
    this.datasetResetEl.addEventListener('click', () => {
      if (!confirm('初期値にリセットします、よろしいですか？')) return;

      this.conversions = _.cloneDeep(this.conversionsInit)
      this.breakdown = _.cloneDeep(this.breakdownInit)
      this.sessions = _.cloneDeep(this.sessionsInit)
      this.updateDisplayData()
      this.resetError()
    })
  }

  updateData() {
    this.sessions.organic.cv = Math.round(Number(this.sessions.organic.s) * Number(this.sessions.organic.cvr / 100))
    this.sessions.organic_social.cv = Math.round(Number(this.sessions.organic_social.s) * Number(this.sessions.organic_social.cvr / 100))
    this.sessions.referral.cv = Math.round(Number(this.sessions.referral.s) * Number(this.sessions.referral.cvr / 100))
    this.sessions.direct.cv = Math.round(Number(this.sessions.direct.s) * Number(this.sessions.direct.cvr / 100))
    this.sessions.email.cv = Math.round(Number(this.sessions.email.s) * Number(this.sessions.email.cvr / 100))

    this.conversions.basic =
      Number(this.sessions.organic.cv) +
      Number(this.sessions.organic_social.cv) +
      Number(this.sessions.referral.cv) +
      Number(this.sessions.direct.cv) +
      Number(this.sessions.email.cv)

    this.breakdown.basic.s =
      Number(this.sessions.organic.s) +
      Number(this.sessions.organic_social.s) +
      Number(this.sessions.referral.s) +
      Number(this.sessions.direct.s) +
      Number(this.sessions.email.s)

    if (Number(this.breakdown.basic.s) === 0) {
      this.breakdown.basic.cvr = 0
    } else {
      this.breakdown.basic.cvr = (Number(this.conversions.basic) / Number(this.breakdown.basic.s) * 100).toFixed(1)
    }

    this.sessions.paid_search.cv = Math.round(Number(this.sessions.paid_search.s) * Number(this.sessions.paid_search.cvr / 100))
    this.sessions.paid_social.cv = Math.round(Number(this.sessions.paid_social.s) * Number(this.sessions.paid_social.cvr / 100))
    this.sessions.display.cv = Math.round(Number(this.sessions.display.s) * Number(this.sessions.display.cvr / 100))
    this.sessions.video.cv = Math.round(Number(this.sessions.video.s) * Number(this.sessions.video.cvr / 100))
    this.sessions.affiliate.cv = Math.round(Number(this.sessions.affiliate.s) * Number(this.sessions.affiliate.cvr / 100))

    this.sessions.paid_search.cost = Math.round(Number(this.sessions.paid_search.s) * Number(this.sessions.paid_search.cpc))
    this.sessions.paid_social.cost = Math.round(Number(this.sessions.paid_social.s) * Number(this.sessions.paid_social.cpc))
    this.sessions.display.cost = Math.round(Number(this.sessions.display.s) * Number(this.sessions.display.cpc))
    this.sessions.video.cost = Math.round(Number(this.sessions.video.s) * Number(this.sessions.video.cpc))
    this.sessions.affiliate.cost = Math.round(Number(this.sessions.affiliate.s) * Number(this.sessions.affiliate.cpc))

    this.conversions.ad =
      Number(this.sessions.paid_search.cv) +
      Number(this.sessions.paid_social.cv) +
      Number(this.sessions.display.cv) +
      Number(this.sessions.video.cv) +
      Number(this.sessions.affiliate.cv)

    this.breakdown.ad.s =
      Number(this.sessions.paid_search.s) +
      Number(this.sessions.paid_social.s) +
      Number(this.sessions.display.s) +
      Number(this.sessions.video.s) +
      Number(this.sessions.affiliate.s)

    if (Number(this.breakdown.ad.s) === 0) {
      this.breakdown.ad.cvr = 0
    } else {
      this.breakdown.ad.cvr = (Number(this.conversions.ad) / Number(this.breakdown.ad.s) * 100).toFixed(1)
    }

    this.conversions.sum = Number(this.conversions.basic) + Number(this.conversions.ad)

    this.breakdown.all.s =
      Number(this.breakdown.basic.s) +
      Number(this.breakdown.ad.s)

    if (Number(this.breakdown.all.s) === 0) {
      this.breakdown.all.cvr = 0
    } else {
      this.breakdown.all.cvr = (Number(this.conversions.sum) / Number(this.breakdown.all.s) * 100).toFixed(1)
    }
  }

  updateDisplayData() {
    this.conversionsSumEl.value = Number(this.conversions['sum']).toLocaleString()
    this.conversionsBasicEl.value = Number(this.conversions['basic']).toLocaleString()
    this.conversionsAdEl.value = Number(this.conversions['ad']).toLocaleString()

    // NOTE: 基礎体力と広告の内訳合計
    this.sessionsEl.value = Number(this.breakdown['all']['s']).toLocaleString()
    this.cvrEl.value =  Number(this.breakdown['all']['cvr']).toLocaleString()
    this.basicSessionsEl.value =  Number(this.breakdown['basic']['s']).toLocaleString()
    this.basicCvrEl.value =  Number(this.breakdown['basic']['cvr']).toLocaleString()
    this.adSessionsEl.value =  Number(this.breakdown['ad']['s']).toLocaleString()
    this.adCvrEl.value =  Number(this.breakdown['ad']['cvr']).toLocaleString()

    // NOTE: オーガニック検索
    this.sessionsOrganicSEl.value = Number(this.sessions['organic']['s']).toLocaleString()
    this.sessionsOrganicCvrEl.value = Number(this.sessions['organic']['cvr']).toLocaleString()
    this.sessionsOrganicCvEl.value = Number(this.sessions['organic']['cv']).toLocaleString()

    // NOTE: オーガニックソーシャル
    this.sessionsOrganicSocialSEl.value = Number(this.sessions['organic_social']['s']).toLocaleString()
    this.sessionsOrganicSocialCvrEl.value = Number(this.sessions['organic_social']['cvr']).toLocaleString()
    this.sessionsOrganicSocialCvEl.value = Number(this.sessions['organic_social']['cv']).toLocaleString()

    // NOTE: 参照URL
    this.sessionsReferralSEl.value = Number(this.sessions['referral']['s']).toLocaleString()
    this.sessionsReferralCvrEl.value = Number(this.sessions['referral']['cvr']).toLocaleString()
    this.sessionsReferralCvEl.value = Number(this.sessions['referral']['cv']).toLocaleString()

    // NOTE: ノーリファラー
    this.sessionsDirectSEl.value = Number(this.sessions['direct']['s']).toLocaleString()
    this.sessionsDirectCvrEl.value = Number(this.sessions['direct']['cvr']).toLocaleString()
    this.sessionsDirectCvEl.value = Number(this.sessions['direct']['cv']).toLocaleString()

    // NOTE: メール
    this.sessionsEmailSEl.value = Number(this.sessions['email']['s']).toLocaleString()
    this.sessionsEmailCvrEl.value = Number(this.sessions['email']['cvr']).toLocaleString()
    this.sessionsEmailCvEl.value = Number(this.sessions['email']['cv']).toLocaleString()

    // NOTE: 有料検索
    this.sessionsPaidSearchSEl.value = Number(this.sessions['paid_search']['s']).toLocaleString()
    this.sessionsPaidSearchCvrEl.value = Number(this.sessions['paid_search']['cvr']).toLocaleString()
    this.sessionsPaidSearchCvEl.value = Number(this.sessions['paid_search']['cv']).toLocaleString()
    this.sessionsPaidSearchCpcEl.value = Number(this.sessions['paid_search']['cpc']).toLocaleString()
    this.sessionsPaidSearchCostEl.value = Number(this.sessions['paid_search']['cost']).toLocaleString()

    // NOTE: 有料ソーシャル
    this.sessionsPaidSocialSEl.value = Number(this.sessions['paid_social']['s']).toLocaleString()
    this.sessionsPaidSocialCvrEl.value = Number(this.sessions['paid_social']['cvr']).toLocaleString()
    this.sessionsPaidSocialCvEl.value = Number(this.sessions['paid_social']['cv']).toLocaleString()
    this.sessionsPaidSocialCpcEl.value = Number(this.sessions['paid_social']['cpc']).toLocaleString()
    this.sessionsPaidSocialCostEl.value = Number(this.sessions['paid_social']['cost']).toLocaleString()

    // NOTE: ディスプレイ
    this.sessionsDisplaySEl.value = Number(this.sessions['display']['s']).toLocaleString()
    this.sessionsDisplayCvrEl.value = Number(this.sessions['display']['cvr']).toLocaleString()
    this.sessionsDisplayCvEl.value = Number(this.sessions['display']['cv']).toLocaleString()
    this.sessionsDisplayCpcEl.value = Number(this.sessions['display']['cpc']).toLocaleString()
    this.sessionsDisplayCostEl.value = Number(this.sessions['display']['cost']).toLocaleString()

    // NOTE: 有料動画
    this.sessionsVideoSEl.value = Number(this.sessions['video']['s']).toLocaleString()
    this.sessionsVideoCvrEl.value = Number(this.sessions['video']['cvr']).toLocaleString()
    this.sessionsVideoCvEl.value = Number(this.sessions['video']['cv']).toLocaleString()
    this.sessionsVideoCpcEl.value = Number(this.sessions['video']['cpc']).toLocaleString()
    this.sessionsVideoCostEl.value = Number(this.sessions['video']['cost']).toLocaleString()

    // NOTE: アフィリエイト
    this.sessionsAffiliateSEl.value = Number(this.sessions['affiliate']['s']).toLocaleString()
    this.sessionsAffiliateCvrEl.value = Number(this.sessions['affiliate']['cvr']).toLocaleString()
    this.sessionsAffiliateCvEl.value = Number(this.sessions['affiliate']['cv']).toLocaleString()
    this.sessionsAffiliateCpcEl.value = Number(this.sessions['affiliate']['cpc']).toLocaleString()
    this.sessionsAffiliateCostEl.value = Number(this.sessions['affiliate']['cost']).toLocaleString()

    // NOTE: 広告費合計
    this.adCostSumEl.innerHTML = Number(
      Number(this.sessions['paid_search']['cost']) +
      Number(this.sessions['paid_social']['cost']) +
      Number(this.sessions['display']['cost']) +
      Number(this.sessions['video']['cost']) +
      Number(this.sessions['affiliate']['cost'])
    ).toLocaleString()
  }

  resetError() {
    // NOTE: オーガニック検索
    this.sessionsOrganicSEl.classList.remove('has-error')
    this.sessionsOrganicCvrEl.classList.remove('has-error')

    // NOTE: オーガニックソーシャル
    this.sessionsOrganicSocialSEl.classList.remove('has-error')
    this.sessionsOrganicSocialCvrEl.classList.remove('has-error')

    // NOTE: 参照URL
    this.sessionsReferralSEl.classList.remove('has-error')
    this.sessionsReferralCvrEl.classList.remove('has-error')

    // NOTE: ノーリファラー
    this.sessionsDirectSEl.classList.remove('has-error')
    this.sessionsDirectCvrEl.classList.remove('has-error')

    // NOTE: メール
    this.sessionsEmailSEl.classList.remove('has-error')
    this.sessionsEmailCvrEl.classList.remove('has-error')

    // NOTE: 有料検索
    this.sessionsPaidSearchSEl.classList.remove('has-error')
    this.sessionsPaidSearchCvrEl.classList.remove('has-error')
    this.sessionsPaidSearchCpcEl.classList.remove('has-error')

    // NOTE: 有料ソーシャル
    this.sessionsPaidSocialSEl.classList.remove('has-error')
    this.sessionsPaidSocialCvrEl.classList.remove('has-error')
    this.sessionsPaidSocialCpcEl.classList.remove('has-error')

    // NOTE: ディスプレイ
    this.sessionsDisplaySEl.classList.remove('has-error')
    this.sessionsDisplayCvrEl.classList.remove('has-error')
    this.sessionsDisplayCpcEl.classList.remove('has-error')

    // NOTE: 有料動画
    this.sessionsVideoSEl.classList.remove('has-error')
    this.sessionsVideoCvrEl.classList.remove('has-error')
    this.sessionsVideoCpcEl.classList.remove('has-error')

    // NOTE: アフィリエイト
    this.sessionsAffiliateSEl.classList.remove('has-error')
    this.sessionsAffiliateCvrEl.classList.remove('has-error')
    this.sessionsAffiliateCpcEl.classList.remove('has-error')

  }

  elementsIsNull(...elements) {
    let isNull = false;
    elements.some( (element) => {
      if (element === null) {
        isNull = true;
        return isNull;
      }
    });
    return isNull;
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
