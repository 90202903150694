import Base from "./base.js"

class ScrollBack extends Base {
  constructor(element) {
    super()

    this.ACTIVE_CLASS_NAME = 'active'
    this.HIDE_CLASS_NAME = 'hide'
    this.scrollBackEl = element
    this.mainEl = document.querySelector('main')
    this.setPosition = document.documentElement.scrollTop
    this.mainHeight = 0
    this.ticking = false

    this.update()
  }

  update() {
    if (super.elementsIsNull(this.mainEl)) return

    this.mainHeight = this.mainEl.offsetHeight
    this.scrollBackEl.classList.remove(this.activeClass, this.hideClass)

    window.addEventListener('scroll', this.handleScroll.bind(this))
  }

  handleScroll() {
    if (!this.ticking) {
      requestAnimationFrame(() => {
        this.scrollBackObserver()
        this.ticking = false
      })
      this.ticking = true
    }
  }

  scrollBackObserver() {
    const scrollY = window.scrollY

    if (scrollY < this.mainHeight) {
      this.scrollBackEl.classList.add(this.ACTIVE_CLASS_NAME)
    } else {
      this.scrollBackEl.classList.remove(this.ACTIVE_CLASS_NAME)
    }

    if (this.setPosition < scrollY) {
      this.scrollBackEl.classList.add(this.HIDE_CLASS_NAME)
    } else {
      this.scrollBackEl.classList.remove(this.HIDE_CLASS_NAME)
    }

    this.setPosition = scrollY
  }
}

export default ScrollBack
