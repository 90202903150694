import Base from "./base.js"

class Tab extends Base {
  constructor(element) {
    super()

    this.RESIZE_TIMEOUT = 200
    this.TRANSITION_DURATION = 500

    // NOTE: DOMを吐き出す場所指定element
    this.tabContainerEl = element
    this.tabButtonsEl = this.tabContainerEl.querySelector('[data-tab-buttons]')
    this.tabButtonEls = this.tabContainerEl.querySelectorAll('[data-tab-button]')
    this.tabButtonActiveEl = this.tabContainerEl.querySelector('[data-tab-button].active')
    this.tabContentEls = this.tabContainerEl.querySelectorAll('[data-tab-content]')

    // NOTE: 選択されているボタンIndex
    this.currentButtonIndex = Array.from(this.tabButtonActiveEl.parentNode.children).indexOf(this.tabButtonActiveEl) + 1 // NOTE: 計算しやすくするために +1

    // NOTE: tab / tab button property
    this.tabButtonCSS = window.getComputedStyle(this.tabButtonEls[0])

    // NOTE: update element data
    this.updateElementData()

    // NOTE: pager
    this.tabPrevEl = this.tabContainerEl.querySelector('[data-tab-pager="prev"]')
    this.tabNextEl = this.tabContainerEl.querySelector('[data-tab-pager="next"]')
    this.tabPrevEl.classList.add('disable-tab-button')
    this.tabNextEl.classList.add('disable-tab-button')

    this.initialize()
  }

  initialize() {
    // NOTE: 初回 update display
    this.updateDisplay()

    // NOTE: tab button クリックでタブ切り替えの処理
    for (let i = 0; i < this.tabButtonEls.length; i++) {
      this.tabButtonEls[i].addEventListener('click', (e) => {
        const currentTabIndex = e.currentTarget.dataset.tab

        this.updateTabByIndex(currentTabIndex)
      })
    }

    // NOTE: Prev クリック処理
    this.tabPrevEl.addEventListener('click', () => {
      const prevIndex = this.currentPageIndex - 1
      this.updateIndexByPager(prevIndex)
      this.updateTabByIndex(this.currentButtonIndex)
      this.updateDisplay()
    })

    // NOTE: Next クリック処理
    this.tabNextEl.addEventListener('click', () => {
      const nextIndex = this.currentPageIndex + 1
      this.updateIndexByPager(nextIndex)
      this.updateTabByIndex(this.currentButtonIndex)
      this.updateDisplay()
    })

    // NOTE: width resize でタブを update する監視
    this.observerResize()
  }

  updateElementData() {
    // NOTE: tab / tab button property
    this.tabButtonMinWidthNum = this.tabButtonCSS.getPropertyValue('min-width').split('px')[0]
    this.tabButtonContainerWidth = this.tabButtonMinWidthNum * this.tabButtonEls.length
    this.tabWidth = this.tabContainerEl.offsetWidth - 64 // NOTE: prev/next -> 32px

    // NOTE: はみ出している px, 個数 を出す
    this.pxOfOverhanging = this.tabButtonContainerWidth - this.tabWidth
    this.numberOfOverhanging = Math.ceil(this.pxOfOverhanging / this.tabButtonMinWidthNum)

    // NOTE: 1ページに入るボタンの個数
    this.numberOfButtonsPerPage = this.tabButtonEls.length - this.numberOfOverhanging

    // NOTE: 全ページ数
    this.totalNumberOfPages = 1 + Math.ceil(this.numberOfOverhanging / this.numberOfButtonsPerPage)

    // NOTE: initial translate px
    this.tabTranslateX = 0

    // NOTE: 現在のページIndex
    this.currentPageIndex = Math.ceil(this.currentButtonIndex / this.numberOfButtonsPerPage)
  }

  updateIndexByPager(index) {
    if (!index) return

    // NOTE: update current page index
    this.currentPageIndex = index

    // NOTE: ページ移動の際は、ページ先頭の button を active にする
    this.currentButtonIndex = 1 + ((index - 1) * this.numberOfButtonsPerPage)
  }

  updateDisplay() {
    // NOTE: 最初に計算に使用するデータを更新する
    this.updateElementData()

    // NOTE: this.currentPageIndex によって、 this.tabTranslateX を更新する
    this.tabTranslateX = this.tabWidth * (this.currentPageIndex - 1) * -1

    // NOTE: 処理前に prev / next の表示をリセットする
    this.resetPrevNext()

    // console.dir(`
    //   タブ全体: ${this.tabWidth}
    //   タブボタン合計: ${this.tabButtonContainerWidth}
    //   はみ出しているpx: ${this.pxOfOverhanging}
    //   1ページに入るボタンの個数: ${this.numberOfButtonsPerPage}
    //   全ページ数: ${this.totalNumberOfPages}
    //   現在 active なボタンは何番目か: ${this.currentButtonIndex}
    //   現在のページ番号: ${this.currentPageIndex} page me
    //   translateX px: ${this.tabTranslateX}
    // `)

    for (let i = 0; i < this.tabButtonEls.length; i++) {
      // NOTE: pager ありの場合
      if (this.tabButtonContainerWidth > this.tabWidth) {
        this.tabButtonEls[i].style.width = `calc(100% / ${this.numberOfButtonsPerPage})`
        this.tabButtonsEl.style.transform = `translateX(${this.tabTranslateX}px)`

        if (this.currentPageIndex !== 1) {
          this.tabPrevEl.classList.remove('disable-tab-button')
        }

        if (this.currentPageIndex !== this.totalNumberOfPages) {
          this.tabNextEl.classList.remove('disable-tab-button')
        }

      // NOTE: pager なしの場合
      } else {
        this.tabButtonEls[i].style.width = `calc(100% / ${this.tabButtonEls.length})`
        this.tabButtonsEl.style.transform = `translateX(0px)`

      }
    }
  }

  updateTabByIndex(index) {
    const currentButtonEl = this.tabContainerEl.querySelector(`[data-tab-button][data-tab='${index}']`)
    const currentContentEl = this.tabContainerEl.querySelector(`[data-tab-content][data-tab='${index}']`)

    // NOTE: ボタンのactiveクラスを切り替える
    for (let i = 0; i < this.tabButtonEls.length; i++) {
      this.tabButtonEls[i].classList.remove('active')
    }
    currentButtonEl.classList.add('active')

    // NOTE: コンテンツのactiveクラスを切り替える
    for (let i = 0; i < this.tabContentEls.length; i++) {
      this.tabContentEls[i].dataset.toggleFade = ''
      this.tabContentEls[i].classList.remove('active')
    }
    currentContentEl.dataset.toggleFade = 'active'
    currentContentEl.classList.add('active')

    // NOTE: update current button index
    this.currentButtonIndex = index
  }

  observerResize() {
    let timer = false
    let prevWidth = 0
    const observer = new ResizeObserver((entries) => {
      // NOTE: only width observe
      for (const entry of entries) {
        const width = entry.borderBoxSize?.[0].inlineSize
        if (typeof width === 'number' && width !== prevWidth) {
          prevWidth = width

          // NOTE: resize timeout だけ待機する
          if (timer !== false) {
            clearTimeout(timer)
          }
          timer = setTimeout( () => {
            this.updateDisplay()
          }, this.RESIZE_TIMEOUT)
        }
      }
    })
    observer.observe(this.tabContainerEl, {
      attributes: true,
      childList: true,
      subtree: true
    })
  }

  resetPrevNext() {
    this.tabPrevEl.classList.add('disable-tab-button')
    this.tabNextEl.classList.add('disable-tab-button')
  }
}

export default Tab
