import Base from "./base.js"

class SelectBoxSingle extends Base {
  constructor(element) {
    super()

    this.PARENT_CLASS_NAME = 'selectbox__single'
    this.ACTIVE_CLASS_NAME = 'selectbox__active--single'
    this.ACTIVE_ITEM_CLASS_NAME = 'selectbox__activeItem--single'
    this.LIST_CLASS_NAME = 'selectbox__list'
    this.PLACEHOLDER_CLASS_NAME = 'selectbox__placeholder'
    this.ARROW_CLASS_NAME = 'selectbox__arrow'
    this.TRANSITION_DURATION = 500
    this.UNSELECT_TEXT = 'Unselect'

    // NOTE: DOMを吐き出す場所指定element
    this.selectContainerEl = element

    // NOTE: ここでは実際にレンダリングされたselectタグを使用する
    this.selectEl = this.selectContainerEl.querySelector('select')
    this.optionEls = this.selectEl.querySelectorAll('option')

    // NOTE: 全体の親div
    this.selectParentDOM = document.createElement('div')
    this.selectParentDOM.className = this.PARENT_CLASS_NAME

    // NOTE: 選択状態のものがテキストBoxに入るところのdiv
    this.selectActiveDOM = document.createElement('div')
    this.selectActiveDOM.className = this.ACTIVE_CLASS_NAME

    // NOTE: 非選択状態のものが入るul
    this.selectInactiveListDOM = document.createElement('ul')
    this.selectInactiveListDOM.className = this.LIST_CLASS_NAME

    // NOTE: プレースホルダが入るspan
    this.selectPlaceholderDOM = document.createElement('span')
    this.selectPlaceholderDOM.className = this.PLACEHOLDER_CLASS_NAME
    this.selectPlaceholderDOM.innerText = this.selectEl.dataset.placeholder
    this.selectPlaceholderDOM.appendChild(this.selectEl)

    // NOTE: arrow DOM
    this.selectArrowDOM = document.createElement('div')
    this.selectArrowDOM.className = this.ARROW_CLASS_NAME
    this.selectArrowDOM.dataset.hover = 'dark'
    this.selectArrowIDOM = document.createElement('i')
    this.selectArrowIDOM.className = 'fa-sharp fa-light fa-caret-down'
    this.selectArrowDOM.appendChild(this.selectArrowIDOM)

    // NOTE: observer監視対象
    this.selectMultipleEl = {}
    this.selectMultipleActiveEl = {}
    this.selectMultipleActiveEls = {}
    this.triggerArrowEl = {}
    this.placeholderEl = {}
    this.nonActiveListEl = {}
    this.nonActiveListEls = {}

    this.initialize()
  }

  initialize() {
    for (let i = 0; i < this.optionEls.length; i++) {
      const text = this.optionEls[i].innerText // NOTE: それぞれのoptionのテキスト（＊１）
      const value = this.optionEls[i].value // NOTE: それぞれのoptionのvalue

      // NOTE: デフォルトでセレクトされているものがあった場合はactiveの中にいれる
      if (this.optionEls[i].selected) {
        this.updateActiveArea(text, value, false)

        // NOTE: Selectboxリスト作成
        this.createInactiveList(text, value, 'removed') // NOTE: デフォルトでセレクトされているものはremovedクラス付与

        if (value) this.selectPlaceholderDOM.classList.add('hide') // NOTE: デフォルトセレクトが一つでもあった場合 / かつ、value=""ではない時はプレースホルダ非表示

      } else {
        // NOTE: Selectboxリスト作成
        this.createInactiveList(text, value, '') // NOTE: デフォルトでセレクトされていないものは表示する
      }
    }

    // NOTE: プレースホルダと、矢印を選択状態エリアの中に追加
    this.selectActiveDOM.appendChild(this.selectPlaceholderDOM)
    this.selectActiveDOM.appendChild(this.selectArrowDOM)

    // 全体の親DOM構成を作成
    this.selectParentDOM.appendChild(this.selectActiveDOM) // 選択状態エリア
    this.selectParentDOM.appendChild(this.selectInactiveListDOM) // 非選択状態のリスト

    // ラッパーに実際に生成したDOMを追加する
    this.selectContainerEl.innerHTML = '' // NOTE: DOM初期化
    this.selectContainerEl.appendChild(this.selectParentDOM)

    this.observer()
    this.toggleList()

    this.handleChange = this.handleChange.bind(this);
  }

  // NOTE: Element情報更新
  observer() {
    this.selectEl = this.selectContainerEl.querySelector('select')
    this.optionEls = this.selectEl.querySelectorAll('option')
    this.selectMultipleEl = this.selectContainerEl.querySelector(`.${this.PARENT_CLASS_NAME}`)
    this.selectMultipleActiveEl = this.selectContainerEl.querySelector(`.${this.ACTIVE_CLASS_NAME}`)
    this.selectMultipleActiveEls = this.selectContainerEl.querySelectorAll(`.${this.ACTIVE_ITEM_CLASS_NAME}`)
    this.triggerArrowEl = this.selectContainerEl.querySelector(`.${this.ARROW_CLASS_NAME}`)
    this.placeholderEl = this.selectContainerEl.querySelector(`.${this.PLACEHOLDER_CLASS_NAME}`)
    this.nonActiveListEl = this.selectContainerEl.querySelector(`.${this.LIST_CLASS_NAME}`)
    this.nonActiveListEls = this.selectContainerEl.querySelectorAll(`.${this.LIST_CLASS_NAME} li`)
  }

  // NOTE: 選択状態のものを生成するfunction
  updateActiveArea(text, value, animationFlag) {
    const aDOM = document.createElement('a')
    const emDOM = document.createElement('em')

    emDOM.innerText = text
    aDOM.appendChild(emDOM)
    aDOM.className = this.ACTIVE_ITEM_CLASS_NAME
    aDOM.dataset.value = value
    aDOM.style.pointerEvents = 'none'

    if (animationFlag) aDOM.classList.add('show')

    const target = this.selectActiveDOM.querySelector('a')
    if (!super.elementsIsNull(target)) {
      target.parentNode.removeChild(target) // NOTE: 残っているaタグを削除する
    }

    if (value) this.selectActiveDOM.appendChild(aDOM) // NOTE: 非選択状態の場合は削除のみ
  }

  // NOTE: 非選択状態のものを初期作成するfunction
  createInactiveList(text, value, className) {
    const liDOM = document.createElement('li')
    liDOM.className = className

    // NOTE: valueがない場合はvalue=""なので、未選択テキストにする
    if (value) {
      liDOM.innerText = text
    } else {
      liDOM.innerText = this.UNSELECT_TEXT
      liDOM.classList.add('empty')
    }
    liDOM.dataset.value = value
    liDOM.dataset.hover = 'dark'

    const result = this.selectInactiveListDOM.appendChild(liDOM)
    result.addEventListener('click', () => {
      this.add(result)
    })
  }

  // NOTE: リストの開閉制御
  toggleList() {
    this.selectMultipleActiveEl.addEventListener('click', (e) => {
      this.observer()

      for (let i = 0; i < this.selectMultipleActiveEls.length; i++) {
        if (e.composedPath().includes(this.selectMultipleActiveEls[i])) return
      }
      this.selectMultipleActiveEl.classList.toggle('open')
      this.nonActiveListEl.classList.toggle('open')
    })
    // NOTE: singleの場合は、選択リストクリックでリスト開閉する
    for (let i = 0; i < this.nonActiveListEls.length; i++) {
      this.nonActiveListEls[i].addEventListener('click', () => {
        this.selectMultipleActiveEl.classList.toggle('open')
        this.nonActiveListEl.classList.toggle('open')
      })
    }
    document.addEventListener('click', (e) => {
      if (e.composedPath().includes(this.selectMultipleEl)) return
      this.selectMultipleActiveEl.classList.remove('open')
      this.nonActiveListEl.classList.remove('open')
    })
  }

  add(element) {
    if (this.selectMultipleEl.classList.contains('progress')) return // NOTE: アニメーション中は処理しない
    this.selectMultipleEl.classList.add('progress')
    // NOTE: singleの場合は選択時にリスト全体のremovedクラスをリセット
    for (let i = 0; i < this.nonActiveListEls.length; i++) {
      this.nonActiveListEls[i].classList.remove('removed')
    }
    element.classList.add('remove')
    this.updateActiveArea(element.innerText, element.dataset.value, true)
    if (element.dataset.value) this.placeholderEl.classList.add('hide') // NOTE: 未選択の時はhideしない

    for (let i = 0; i < this.optionEls.length; i++) {
      console.dir(`this.optionEls[i].innerText: ${this.optionEls[i].innerText} / element.innerText: ${element.innerText}`)
      if (this.optionEls[i].innerText == element.innerText) {
        this.optionEls[i].selected = true
        this.handleChange(this.optionEls[i])

      // NOTE: 未選択にする場合
      } else if (this.optionEls[i].innerText == '' && element.innerText == this.UNSELECT_TEXT) {
        this.optionEls[i].selected = true
        this.handleChange(this.optionEls[i])
      }
    }

    setTimeout(() => {
      element.classList.add('removed') // NOTE: singleの場合は選択リストの対象にremovedクラスを付与
      element.classList.remove('remove')
      this.selectMultipleEl.classList.remove('progress') // NOTE: アニメーション終了

      if (!element.dataset.value) this.placeholderEl.classList.remove('hide') // NOTE: 未選択の時のみhide
    }, this.TRANSITION_DURATION)
  }

  handleChange(event) {
    if (typeof this.callback === 'function') {
      this.callback(event)
    }
  }

  onChange(callback) {
    this.callback = callback
  }
}

class SelectBoxMultiple extends Base {
  constructor(element) {
    super()

    this.PARENT_CLASS_NAME = 'selectbox__multiple'
    this.ACTIVE_CLASS_NAME = 'selectbox__active'
    this.ACTIVE_ITEM_CLASS_NAME = 'selectbox__activeItem'
    this.LIST_CLASS_NAME = 'selectbox__list'
    this.PLACEHOLDER_CLASS_NAME = 'selectbox__placeholder'
    this.ARROW_CLASS_NAME = 'selectbox__arrow'
    this.TRANSITION_DURATION = 500

    // NOTE: DOMを吐き出す場所指定element
    this.selectContainerEl = element

    // NOTE: ここでは実際にレンダリングされたselectタグを使用する
    this.selectEl = this.selectContainerEl.querySelector('select[multiple]')
    this.optionEls = this.selectEl.querySelectorAll('option')

    // NOTE: 全体の親div
    this.selectParentDOM = document.createElement('div')
    this.selectParentDOM.className = this.PARENT_CLASS_NAME

    // NOTE: 選択状態のものがテキストBoxに入るところのdiv
    this.selectActiveDOM = document.createElement('div')
    this.selectActiveDOM.className = this.ACTIVE_CLASS_NAME

    // NOTE: 非選択状態のものが入るul
    this.selectInactiveListDOM = document.createElement('ul')
    this.selectInactiveListDOM.className = this.LIST_CLASS_NAME

    // NOTE: プレースホルダが入るspan
    this.selectPlaceholderDOM = document.createElement('span')
    this.selectPlaceholderDOM.className = this.PLACEHOLDER_CLASS_NAME
    this.selectPlaceholderDOM.innerText = this.selectEl.dataset.placeholder
    this.selectPlaceholderDOM.appendChild(this.selectEl)

    // NOTE: arrow DOM
    this.selectArrowDOM = document.createElement('div')
    this.selectArrowDOM.className = this.ARROW_CLASS_NAME
    this.selectArrowDOM.dataset.hover = 'dark'
    this.selectArrowIDOM = document.createElement('i')
    this.selectArrowIDOM.className = 'fa-sharp fa-light fa-caret-down'
    this.selectArrowDOM.appendChild(this.selectArrowIDOM)

    // NOTE: observer監視対象
    this.selectMultipleEl = {}
    this.selectMultipleActiveEl = {}
    this.selectMultipleActiveEls = {}
    this.triggerArrowEl = {}
    this.placeholderEl = {}
    this.nonActiveListEl = {}
    this.nonActiveListEls = {}

    this.initialize()
  }

  initialize() {
    for (let i = 0; i < this.optionEls.length; i++) {
      const text = this.optionEls[i].innerText // NOTE: それぞれのoptionのテキスト（＊１）
      const value = this.optionEls[i].value // NOTE: それぞれのoptionのvalue

      // NOTE: デフォルトでセレクトされているものがあった場合はactiveの中にいれる
      if (this.optionEls[i].selected) {
        this.updateActiveArea(text, value, false)

        // NOTE: Selectboxリスト作成
        this.createInactiveList(text, value, 'none') // NOTE: デフォルトでセレクトされているものはdisplay: none;

        this.selectPlaceholderDOM.classList.add('hide') // NOTE: デフォルトセレクトが一つでもあった場合はプレースホルダ非表示

      } else {
        // NOTE: Selectboxリスト作成
        this.createInactiveList(text, value, '') // NOTE: デフォルトでセレクトされていないものは表示する
      }
    }

    // NOTE: プレースホルダと、矢印を選択状態エリアの中に追加
    this.selectActiveDOM.appendChild(this.selectPlaceholderDOM)
    this.selectActiveDOM.appendChild(this.selectArrowDOM)

    // 全体の親DOM構成を作成
    this.selectParentDOM.appendChild(this.selectActiveDOM) // 選択状態エリア
    this.selectParentDOM.appendChild(this.selectInactiveListDOM) // 非選択状態のリスト

    // ラッパーに実際に生成したDOMを追加する
    this.selectContainerEl.innerHTML = '' // NOTE: DOM初期化
    this.selectContainerEl.appendChild(this.selectParentDOM)

    this.observer()
    this.toggleList()
  }

  // NOTE: Element情報更新
  observer() {
    this.selectEl = this.selectContainerEl.querySelector('select[multiple]')
    this.optionEls = this.selectEl.querySelectorAll('option')
    this.selectMultipleEl = this.selectContainerEl.querySelector(`.${this.PARENT_CLASS_NAME}`)
    this.selectMultipleActiveEl = this.selectContainerEl.querySelector(`.${this.ACTIVE_CLASS_NAME}`)
    this.selectMultipleActiveEls = this.selectContainerEl.querySelectorAll(`.${this.ACTIVE_ITEM_CLASS_NAME}`)
    this.triggerArrowEl = this.selectContainerEl.querySelector(`.${this.ARROW_CLASS_NAME}`)
    this.placeholderEl = this.selectContainerEl.querySelector(`.${this.PLACEHOLDER_CLASS_NAME}`)
    this.nonActiveListEl = this.selectContainerEl.querySelector(`.${this.LIST_CLASS_NAME}`)
    this.nonActiveListEls = this.selectContainerEl.querySelectorAll(`.${this.LIST_CLASS_NAME} li`)
  }

  // NOTE: 選択状態のものを生成するfunction
  updateActiveArea(text, value, animationFlag) {
    const aDOM = document.createElement('a')
    const emDOM = document.createElement('em')
    const iDOM = document.createElement('i')

    emDOM.innerText = text
    aDOM.appendChild(emDOM)
    iDOM.className = 'fa-sharp fa-light fa-xmark'
    aDOM.appendChild(iDOM)
    aDOM.className = this.ACTIVE_ITEM_CLASS_NAME
    aDOM.dataset.value = value
    aDOM.dataset.hover = 'dark'

    if (animationFlag) aDOM.classList.add('show')

    const result = this.selectActiveDOM.appendChild(aDOM)
    result.addEventListener('click', () => {
      this.remove(result)
    })
  }

  // NOTE: 非選択状態のものを初期作成するfunction
  createInactiveList(text, value, display) {
    const liDOM = document.createElement('li')
    liDOM.innerText = text
    liDOM.dataset.value = value
    liDOM.dataset.hover = 'dark'
    liDOM.style.display = display

    const result = this.selectInactiveListDOM.appendChild(liDOM)
    result.addEventListener('click', () => {
      this.add(result)
    })
  }

  // NOTE: 非選択状態のものをリストにもどすfunction
  updateInactiveList(value) {
    for (let i = 0; i < this.nonActiveListEls.length; i++) {
      if (value == this.nonActiveListEls[i].dataset.value) {
        this.nonActiveListEls[i].classList.add('show')
        this.nonActiveListEls[i].style.display = ''

        setTimeout(() => {
          this.nonActiveListEls[i].classList.remove('show')
        }, this.TRANSITION_DURATION)
      }
    }
  }

  // NOTE: リストの開閉制御
  toggleList() {
    this.selectMultipleActiveEl.addEventListener('click', (e) => {
      this.observer()

      for (let i = 0; i < this.selectMultipleActiveEls.length; i++) {
        if (e.composedPath().includes(this.selectMultipleActiveEls[i])) return
      }
      this.selectMultipleActiveEl.classList.toggle('open')
      this.nonActiveListEl.classList.toggle('open')
    })
    document.addEventListener('click', (e) => {
      if (e.composedPath().includes(this.selectMultipleEl)) return
      this.selectMultipleActiveEl.classList.remove('open')
      this.nonActiveListEl.classList.remove('open')
    })
  }

  add(element) {
    if (this.selectMultipleEl.classList.contains('progress')) return // NOTE: アニメーション中は処理しない
    this.selectMultipleEl.classList.add('progress')
    element.classList.add('remove')
    this.updateActiveArea(element.innerText, element.dataset.value, true)
    this.placeholderEl.classList.add('hide')

    for (let i = 0; i < this.optionEls.length; i++) {
      if (this.optionEls[i].value == element.dataset.value) this.optionEls[i].selected = true
    }

    setTimeout(() => {
      element.style.display = 'none'
      element.classList.remove('remove')
      this.selectMultipleEl.classList.remove('progress') // NOTE: アニメーション終了
    }, this.TRANSITION_DURATION)
  }

  remove(element) {
    if (this.selectMultipleEl.classList.contains('progress')) return // NOTE: アニメーション中は処理しない
    this.selectMultipleEl.classList.add('progress')
    element.classList.add('remove')
    this.updateInactiveList(element.dataset.value)

    for (let i = 0; i < this.optionEls.length; i++) {
      if (this.optionEls[i].value == element.dataset.value) this.optionEls[i].selected = false
    }

    setTimeout(() => {
      element.parentNode.removeChild(element) // NOTE: 自身を削除する
      this.selectMultipleEl.classList.remove('progress') // NOTE: アニメーション終了

      this.observer()
      if (this.selectMultipleActiveEls.length < 1) this.placeholderEl.classList.remove('hide')
    }, this.TRANSITION_DURATION)
  }
}

export const SelectBox = {
  SelectBoxSingle,
  SelectBoxMultiple,
}
