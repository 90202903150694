import Base from "./base.js"

class SlideToggle extends Base {
  constructor(element) {
    super()

    this.DURATION_DEFAULT = 500
    this.EASE_DEFAULT = 'cubic-bezier(0.075, 0.82, 0.165, 1)'
    this.triggerEl = element
    this.targetId = this.triggerEl.dataset.slideToggle
    this.targetEl = document.getElementById(this.targetId)
    this.initialize()
  }
  initialize() {
    this.triggerEl.addEventListener('click', () => {
      this.toggle()
    })
  }
  updateIcon(process = 'up') {
    const iconUpEl = document.querySelector(`[data-slide-toggle-icon-up="${this.targetId}"]`)
    const iconDownEl = document.querySelector(`[data-slide-toggle-icon-down="${this.targetId}"]`)

    if(super.elementsIsNull(iconUpEl, iconDownEl)) return

    if (process == 'up') {
      iconUpEl.dataset.toggleFade = ''
      iconDownEl.dataset.toggleFade = 'active'
    } else {
      iconUpEl.dataset.toggleFade = 'active'
      iconDownEl.dataset.toggleFade = ''
    }
  }
  toggle() {
    if (window.getComputedStyle(this.targetEl).display === 'none') {
      return this.down()
    } else {
      return this.up()
    }
  }
  down() {
    if (this.targetEl.classList.contains('progress')) return // NOTE: アニメーション中は実行しない

    this.updateIcon('down')

    this.targetEl.style.removeProperty('display')
    let display = window.getComputedStyle(this.targetEl).display
    if (display === 'none') {
      display = 'block'
    }
    this.targetEl.style.display = display
    let height = this.targetEl.offsetHeight
    this.targetEl.style.overflow = 'hidden'
    this.targetEl.style.height = 0
    this.targetEl.style.paddingTop = 0
    this.targetEl.style.paddingBottom = 0
    this.targetEl.style.marginTop = 0
    this.targetEl.style.marginBottom = 0
    this.targetEl.style.opacity = 0
    this.targetEl.offsetHeight
    this.targetEl.style.transitionProperty = 'height, margin, padding, opacity'
    this.targetEl.style.transitionDuration = this.DURATION_DEFAULT + 'ms'
    this.targetEl.style.transitionTimingFunction = this.EASE_DEFAULT
    this.targetEl.style.height = height + 'px'
    this.targetEl.style.opacity = 1
    this.targetEl.style.removeProperty('padding-top')
    this.targetEl.style.removeProperty('padding-bottom')
    this.targetEl.style.removeProperty('margin-top')
    this.targetEl.style.removeProperty('margin-bottom')
    this.targetEl.classList.add('progress')
    setTimeout( () => {
      this.targetEl.style.removeProperty('height')
      this.targetEl.style.removeProperty('overflow')
      this.targetEl.style.removeProperty('transition-duration')
      this.targetEl.style.removeProperty('transition-property')
      this.targetEl.style.removeProperty('transition-timing-function')
      this.targetEl.style.removeProperty('opacity')
      this.targetEl.classList.remove('progress')
    }, this.DURATION_DEFAULT)
  }
  up() {
    if (this.targetEl.classList.contains('progress')) return // NOTE: アニメーション中は実行しない

    this.updateIcon('up')

    this.targetEl.style.height = this.targetEl.offsetHeight + 'px'
    this.targetEl.offsetHeight
    this.targetEl.style.transitionProperty = 'height, margin, padding, opacity'
    this.targetEl.style.transitionDuration = this.DURATION_DEFAULT + 'ms'
    this.targetEl.style.transitionTimingFunction = this.EASE_DEFAULT
    this.targetEl.style.overflow = 'hidden'
    this.targetEl.style.height = 0
    this.targetEl.style.paddingTop = 0
    this.targetEl.style.paddingBottom = 0
    this.targetEl.style.marginTop = 0
    this.targetEl.style.marginBottom = 0
    this.targetEl.style.opacity = 0
    this.targetEl.classList.add('progress')
    setTimeout( () => {
      this.targetEl.style.display = 'none'
      this.targetEl.style.removeProperty('height')
      this.targetEl.style.removeProperty('padding-top')
      this.targetEl.style.removeProperty('padding-bottom')
      this.targetEl.style.removeProperty('margin-top')
      this.targetEl.style.removeProperty('margin-bottom')
      this.targetEl.style.removeProperty('overflow')
      this.targetEl.style.removeProperty('transition-duration')
      this.targetEl.style.removeProperty('transition-property')
      this.targetEl.style.removeProperty('transition-timing-function')
      this.targetEl.style.removeProperty('opacity')
      this.targetEl.classList.remove('progress')
    }, this.DURATION_DEFAULT)
  }
}

export default SlideToggle
