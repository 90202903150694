import Base from "./base.js"

class HashLinkScroll extends Base {
  constructor(id) {
    super()

    this.id = id
    this.targetElById = document.getElementById(this.id)
    this.targetEl = this.targetElById || document.body
    this.headerEl = document.querySelector('header')
    this.headerHeight = this.getElementHeight(this.headerEl) // NOTE: <header>が存在する場合はそのheightを考慮してスクロールする
    this.targetTop = this.getCoords(this.targetEl)
  }

  getElementHeight(element) {
    return super.elementsIsNull(element) ? 0 : element.offsetHeight
  }

  getCoords(element) {
    const box = element.getBoundingClientRect()
    const body = document.body
    const docEl = document.documentElement
    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
    const clientTop = docEl.clientTop || body.clientTop || 0
    const top  = box.top +  scrollTop - clientTop

    return { top: Math.round(top) }
  }

  scrollTarget() {
    window.scroll({
      top: this.targetTop.top - this.headerHeight,
      behavior: 'smooth'
    })
  }
}

export default HashLinkScroll
